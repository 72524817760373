import React, { useEffect, useState } from "react";
import Colors from "../constants/Colors";
import { renderImg } from "../api/requests";
import Label from "./Label";
import Loader from "./Loader";

export default function ImageRender({
  idImg,
  initialImg,
  label,
  color,
  initialName,
  labelWidth,
  labelMargin,
  paddingView,
  resizeMode,
  style,
  bigText,
  dish,
}) {
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(true);

  labelWidth = labelWidth || 100;
  labelMargin = labelMargin || 10;
  color = color || Colors.green;
  resizeMode = resizeMode || "cover";
  initialImg = initialImg || ""; //https://static.wikia.nocookie.net/awakening-of-the-rebellion/images/c/c4/Missing_Image.jpg/revision/latest?cb=20200516103417

  useEffect(() => {
    let unmounted = false;
    if (unmounted) return;

    if (!idImg || idImg === "0" || idImg === "-") {
      setLoading(false);
      setSrc(initialImg);
      return;
    }

    renderImg(idImg)
      .then((imgSrc) => {
        if (!unmounted) setSrc(imgSrc);
      })
      .finally(() => {
        if (!unmounted) setLoading(false);
      });

    return () => {
      unmounted = true;
    };
  }, []);

  if (loading) {
    return (
      <div
        style={{
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: [{ translateX: -10 }, { translateY: -10 }],
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          height: '100%',
        }}
      >
        <Loader loading={loading} />
      </div>
    );
  }

  if (!idImg || idImg === "0" || idImg === "-") {
    if (initialName && !initialImg) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: Colors.primaryLightShadow,
            display: "flex",
            justifyContent: "center",
            position: "relative",
            padding: 15,
            alignItems: "center",
            overflow: "hidden"
          }}
        >
          <p
            style={{
              fontSize: bigText ? 20 : 12,
              textAlign: "center",
              color: Colors.primary,
            }}
          >
            {initialName}
          </p>
          <div style={{ position: "absolute", top: labelMargin, left: labelMargin}}>
            {label && (
              <Label
                labelText={label}
                labelColor={color}
                labelWidth={labelWidth}
                //labelMargin={labelMargin}
              />
            )}
          </div>
        </div>
      );
    }
  }

  return (
    // <div style={{ overflow: "hidden",width: '100%', height: '100%', ...style }}>
    <div
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: resizeMode,
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden"
      }}
    >
      <div style={{ padding: labelMargin }}>
        {label && (
          <Label
            labelText={label}
            labelColor={color}
            labelWidth={labelWidth}
            //labelMargin={labelMargin}
          />
        )}
      </div>
    </div>
    // </div>
  );
}
