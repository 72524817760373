import React from "react";

export default function IngredientCard(props) {
  return (
    <div style={props.style} className='d-flex'>
      <div className="ingredient">
        <div className="d-flex justify-content-between mb-2">
          <p className="ingredient-name">{props.name}</p>
          <p className="ingredient-name ingredient-weight">{props.weight}</p>
        </div>
        <div className="d-flex justify-content-center">
          <p className="ingredient-macros">kcal {props.kcal} </p>
          <span className="ingredient-macros ms-2 me-2">|</span>
          <p className="ingredient-macros">carb {props.carb}</p>
          <span className="ingredient-macros ms-2 me-2">|</span>
          <p className="ingredient-macros">gras {props.gras}</p>
          <span className="ingredient-macros ms-2 me-2">|</span>
          <p className="ingredient-macros">prot {props.prot}</p>
        </div>
      </div>
    </div>
  );
}
