import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api} from "../api/api";

export const fetchExercise = createAsyncThunk(
  'exercise',
  async function () {
    const response = await api.get('/exercise/all')
    return response.data.exercice
  }
)
export const fetchExerciseById = createAsyncThunk("profile/exerciseById", async function (id) {
  try {
    const {data} = await api.post('/exercise/ById', {id});

    return data.Exercice[0];
  } catch (err) {
    console.log('err', err)
  }
});

const initialState = {
  list: [],
  loading: false,
  item: {}
};

export const exercise = createSlice({
  name: "exercise",
  initialState,
  reducers: {
    setExerciseItem: (state, action) => {
      state.item = action.payload
    },
    setExerciseLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: {
    [fetchExercise.fulfilled]: (state, action) => {
      state.list = action.payload
      state.loading = false
    },
    [fetchExerciseById.fulfilled]: (state, action) => {
      state.item = action.payload
      state.loading = false
    },
  },
});


export const {setExerciseItem, setExerciseLoading} = exercise.actions;
export default exercise.reducer;
