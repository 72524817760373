import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Colors from "../constants/Colors";

export default function Loader({ loading, size, color }) {
  size = size || 20;
  color = color || Colors.primary;

  return <ClipLoader color={color} loading={loading} size={size} />;
}
