import React, { useState } from "react";
import { HiX } from "react-icons/hi";
import Button from "./Button";
import Card from "./Card";
import { InputCheck } from "./Input";
import { api } from "../api/api";
import Popup from "../components/Popup";

export default function ChangePasswordModal(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const checkPassword = () => newPassword === repeatPassword;

  const updatePassword = async () => {
    api
      .post("/login/in_change_pw", {
        email: email,
        old: password,
        new: newPassword,
      })
      .then(() => {
        setShowPopup({
          visible: true,
          heading: "FATTO",
          message: "La tua password è stata cambiata con successo!",
          button: "OK",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="changePassword">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <a type="button" data-bs-toggle="modal" data-bs-target="#changePassword" style={{width: '100%'}}>
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="changePassword"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable changePassword-modal-dialog">
          <div className="modal-content">
            <div className="modal-header changePassword-modal-header justify-content-center">
              <button
                type="button"
                className="changePassword-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="changePassword-title">Cambia Password</p>
            </div>
            <div className="modal-body">
              <Card>
                <div className="changePassword-wrapper">
                  <Card>
                    <p className="heading heading-card">Cambia Password</p>
                    <InputCheck
                      description="il vostro log-in"
                      placeholder="Login Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputCheck
                      description="password attuale"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputCheck
                      description="nuova password"
                      placeholder="Nuova Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <InputCheck
                      description="ripeti la nuova password"
                      placeholder="Nuova Password"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    <Button
                      name="Save"
                      type="primary"
                      style={{ marginTop: 30 }}
                      onClick={() => {
                        checkPassword()
                          ? updatePassword()
                          : setShowPopup({
                              visible: true,
                              heading: "ATTENZIONE",
                              message:
                                "Ripetere la nuova password correttamente e riprovare",
                              button: "Riprova",
                              onClose: () => setShowPopup({ visible: false }),
                              onClick: () => setShowPopup({ visible: false }),
                            });
                      }}
                    />
                  </Card>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
