import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const fetchLegacyAll = createAsyncThunk("legacy/all", async function () {
  const response = await api.get("/legacy/all");
  return response.data.list;
});

const initialState = {
  list: [],
  item: {}
};

export const legacy = createSlice({
  name: "legacy",
  initialState,
  reducers: {
    setLegacyItem: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers: {
    [fetchLegacyAll.fulfilled]: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setLegacyItem } = legacy.actions;
export default legacy.reducer;
