import React from "react";
import ReactPlayer from "react-player/vimeo";

export default function VideoPlayer(props) {
  return (
      <ReactPlayer
        url={props.link}
        controls={true}
        width={'100%'}
        height={420}
        onStart={props.onStart}
      />
  );
}
