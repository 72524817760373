import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { FaWindowClose } from "react-icons/fa";
import Card from "../components/Card";
import ProgressCircle from "../components/ProgressCircle";
import Colors from "../constants/Colors";
import Button from "../components/Button";
import Filter from "../components/Filter";
import { api, apiUrl } from "../api/api";
import { fetchAllergyFilter } from "../redux/filter";
import { fetchPersonalDish, setPersonalDishLoading } from "../redux/profile";
import { fetchFavorites } from "../redux/favorites";
import {
  setCategoryMenu,
  setGeneratedMenu,
  setMenuEmpty,
  removeDish,
  setItemModalDish,
  setItemModalFood,
  removeFood,
  fetchCustomCategoryMenu,
  fetchMenuByDate,
} from "../redux/menu";
import {
  sumCarbs,
  sumEnergy,
  sumGras,
  sumProte,
  sumZuccheri,
  sumFibre,
  sumWeight,
} from "../helper/calc/macrosSum";
import AddDish from "../components/AddDish";
import SelectPlate from "./SelectPlate";
import DishCard from "../components/DishCard";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DishDetails from "../components/DishDetails";
import AlimentiDetails from "../components/AlimentiDetails";
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import ClosedContentCard from "../components/ClosedContentCard";
import { IoPlayOutline } from "react-icons/io5";
import { BiCalendar } from "react-icons/bi";
import { BsFilterLeft } from "react-icons/bs";
import IntroAlimentazioneModal from "../components/IntroAlimentazioneModal";
import { fetchCustomFood } from "../redux/food";
import { fetchCustomDish } from "../redux/dish";
import moment from "moment";
import { fixOne } from "../helper/calc/fixOne";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { roundNumber } from "../helper/calc/roundNumber";

export default function Alimentazione() {
  moment.locale("it");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const userType = useSelector((state) => state.profile.userType);
  const userStatus = useSelector((state) => state.profile.userStatus);
  const userContract = useSelector((state) => state.profile.userContract);
  const refeedDay = useSelector((state) => state.profile.refeedDay);
  const myPersonalDish = useSelector((state) => state.profile.personalDish);
  const originalPersonalDish = useSelector(
    (state) => state.profile.originalPersonalDish
  );
  const [personalDish, setPersonalDish] = useState(myPersonalDish);
  const personalDishLoading = useSelector(
    (state) => state.profile.personalDishLoading
  );
  const menu = useSelector((state) => state.menu.menu);
  const menuDish = useSelector((state) => state.menu.menu.menuDish);
  const menuFood = useSelector((state) => state.menu.menu.menuFood);
  const favorites = useSelector((state) => state.favorites.favorites);
  const favoriteDish = useSelector(
    (state) => state.favorites.favorites.favoriteDish
  );
  const favoriteFood = useSelector(
    (state) => state.favorites.favorites.favoriteFood
  );
  const activeAllergy = useSelector((state) => state.filter.activeAllergy);
  const listOrEmpty = Object.keys(activeAllergy).length === 0 ? "off" : "list";
  const [generatedLoading, setGeneratedLoading] = useState(false);
  const customCategoryMenu = useSelector(
    (state) => state.menu.customCategoryMenu
  );
  const [customCategoryMenuItem, setCustomCategoryMenuItem] = useState();
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [strategyCardColor, setStrategyCardColor] = useState("#FFF");
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => {
    // dispatch(setPersonalDishLoading(true));
    dispatch(fetchPersonalDish());
    dispatch(fetchAllergyFilter());
    dispatch(fetchFavorites());
    dispatch(fetchCustomFood());
    dispatch(fetchCustomDish());
    dispatch(fetchCustomCategoryMenu());
  }, []);

  useEffect(() => {
    if (refeedDay && !selectedDay) {
      setPersonalDish(myPersonalDish);
      setStrategyCardColor(Colors.primaryLightShadow);
    } else if (
      refeedDay &&
      moment(selectedDay).format("YYYY-MM-DD") ===
        myPersonalDish?.options?.lastUpdate
    ) {
      setPersonalDish(myPersonalDish);
      setStrategyCardColor(Colors.primaryLightShadow);
    } else {
      setPersonalDish(originalPersonalDish);
      setStrategyCardColor("#FFF");
    }
  }, [selectedDay, myPersonalDish]);

  const checkMenuStatus = () => {
    if (
      menu.menuDish.collazione.length <= 0 &&
      menu.menuDish.pranzo.length <= 0 &&
      menu.menuDish.cena.length <= 0 &&
      menu.menuDish.snack.length <= 0 &&
      menu.menuFood.collazione.length <= 0 &&
      menu.menuFood.pranzo.length <= 0 &&
      menu.menuFood.cena.length <= 0 &&
      menu.menuFood.snack.length <= 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("date", moment(selectedDay).format("YYYY-MM-DD"));
    formdata.append(
      "menu",
      JSON.stringify({ ...menu, status: checkMenuStatus() })
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/profile/updateUserDailyMenu`, requestOptions).catch(
      (error) => console.log("error fetching daily menu", error)
    );
  }, [menuDish, menuFood]);

  useEffect(() => {
    api
      .post("/profile/change_preferated_dish", {
        preferated_dish: favorites,
      })
      .then((response) => console.log("Favorites list uploaded"))
      .catch((err) => console.log("error favorites"));
  }, [favoriteDish, favoriteFood]);

  useEffect(() => {
    const options = personalDish?.options;
    if (options) {
      if (!options.lastReferecend) {
        console.log("here");
        api
          .post("/profile/me_change_personas_dish", {
            json: {
              ...originalPersonalDish,
              options: {
                ...options,
                lastReferecend: options.startDate,
              },
            },
          })
          .catch((err) => console.log(err));
      } else {
        if (
          moment(personalDish.options.lastReferecend, "YYYY-MM-DD")
            .add(personalDish.options.cicleTerm, "days")
            .isSameOrBefore(moment())
        ) {
          api
            .post("/profile/me_change_personas_dish", {
              json: {
                ...originalPersonalDish,
                options: {
                  ...options,
                  lastReferecend: moment().format("YYYY-MM-DD"),
                  used: 0,
                },
              },
            })
            .then(() => {
              dispatch(setPersonalDishLoading(true));
              dispatch(fetchPersonalDish());
            })
            .catch((err) => console.log(err));
        }
      }
    }
  }, []);

  if (personalDishLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={personalDishLoading} size={50} />
      </div>
    );
  }

  if (userType !== "Vincente" || userStatus !== "Attivo") {
    return (
      <div>
        <ClosedContentCard
          heading="Strategia Alimentare"
          content="Scopri il nostro percorso e raggiungi la tua liberta alimentare."
          buttonText="Watch Video"
        />
        {userStatus === "Moderations" && !userContract && (
          <div style={{ width: "30%", margin: "0 auto" }}>
            <Card>
              <p className="heading text-center">
                Per continuare ad usare l’app di Fespa è necessario prendere
                visione ed accettare i termini e le condizioni di utilizzo
              </p>
              <Button
                type="primary"
                name="ACCETTA TERMINI E CONDIZIONI"
                onClick={() => navigate("../../termini")}
              />
            </Card>
          </div>
        )}
      </div>
    );
  }

  const generateCustomMenu = () => {
    api
      .post("/profile/get_m_byPersonas", {
        json: {
          id: personalDish.id,
          categoryName: customCategoryMenuItem,
          conjuction: "or",
          allergenContent: JSON.stringify({ data: activeAllergy }),
        },
      })
      .then((result) => {
        const res = result.data.menu;
        setGeneratedLoading(false);
        const collazione = [];
        const pranzo = [];
        const cena = [];
        const snack = [];

        res && res.breakfast && res.breakfast.map((el) => collazione.push(el));
        res && res.lunch && res.lunch.map((el) => pranzo.push(el));
        res && res.dinner && res.dinner.map((el) => cena.push(el));
        res && res.perekus1 && res.perekus1.map((el) => snack.push(el));
        res && res.perekus2 && res.perekus2.map((el) => snack.push(el));

        !res &&
          setShowPopup({
            visible: true,
            heading: "ATTENZIONE",
            message: "Stiamo cercando dei piatti migliori per te!",
            button: "Riprova",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => {
              generateCustomMenu();
              setGeneratedLoading(true);
              setShowPopup({ visible: false });
            },
          });

        dispatch(setMenuEmpty());
        dispatch(setGeneratedMenu({ collazione, pranzo, cena, snack }));
      })
      .catch((error) => {
        setGeneratedLoading(false);
        console.log("error", error.message);
        setShowPopup({
          visible: true,
          heading: "ATTENZIONE",
          message: "Stiamo cercando dei piatti migliori per te!",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            generateCustomMenu();
            setGeneratedLoading(true);
            setShowPopup({ visible: false });
          },
        });
      });
  };

  const generateMenu = () => {
    api
      .get(
        `/login/generator_new_web_get?T_carboidrati=${
          personalDish.macro_carbo
        }&T_grassi=${personalDish.macro_grasi}&T_proteine=${
          personalDish.macro_prote
        }&allergen=${listOrEmpty}&allergenContent=${JSON.stringify({
          data: activeAllergy,
        })}`
      )
      .then((result) => {
        const res = result.data.json;
        setGeneratedLoading(false);
        const collazione = [];
        const pranzo = [];
        const cena = [];
        const snack = [];

        res &&
          res.collazione &&
          res.collazione.map((el) => collazione.push(el));
        res && res.pranzo && res.pranzo.map((el) => pranzo.push(el));
        res && res.cena && res.cena.map((el) => cena.push(el));
        res && res.spuntini && res.spuntini.map((el) => snack.push(el));

        !res &&
          setShowPopup({
            visible: true,
            heading: "ATTENZIONE",
            message: "Stiamo cercando dei piatti migliori per te!",
            button: "Riprova",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => {
              generateMenu();
              setGeneratedLoading(true);
              setShowPopup({ visible: false });
            },
          });

        dispatch(setMenuEmpty());
        dispatch(setGeneratedMenu({ collazione, pranzo, cena, snack }));
      })
      .catch((error) => {
        setGeneratedLoading(false);
        console.log("error", error);
        setShowPopup({
          visible: true,
          heading: "ATTENZIONE",
          message: "Stiamo cercando dei piatti migliori per te!",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            generateMenu();
            setGeneratedLoading(true);
            setShowPopup({ visible: false });
          },
        });
      });
  };

  const colDish = menuDish?.collazione;
  const colFood = menuFood?.collazione;
  const prDish = menuDish?.pranzo;
  const prFood = menuFood?.pranzo;
  const ceDish = menuDish?.cena;
  const ceFood = menuFood?.cena;
  const snDish = menuDish?.snack;
  const snFood = menuFood?.snack;

  const sumCollazioneEnergy = sumEnergy(colDish, colFood);
  const sumPranzoEnergy = sumEnergy(prDish, prFood);
  const sumCenaEnergy = sumEnergy(ceDish, ceFood);
  const sumSnackEnergy = sumEnergy(snDish, snFood);
  const sumCollazioneCarbs = sumCarbs(colDish, colFood);
  const sumPranzoCarbs = sumCarbs(prDish, prFood);
  const sumCenaCarbs = sumCarbs(ceDish, ceFood);
  const sumSnackCarbs = sumCarbs(snDish, snFood);
  const sumCollazioneProte = sumProte(colDish, colFood);
  const sumPranzoProte = sumProte(prDish, prFood);
  const sumCenaProte = sumProte(ceDish, ceFood);
  const sumSnackProte = sumProte(snDish, snFood);
  const sumCollazioneGras = sumGras(colDish, colFood);
  const sumPranzoGras = sumGras(prDish, prFood);
  const sumCenaGras = sumGras(ceDish, ceFood);
  const sumSnackGras = sumGras(snDish, snFood);
  const sumCollazioneFibre = sumFibre(colDish, colFood);
  const sumPranzoFibre = sumFibre(colDish, colFood);
  const sumCenaFibre = sumFibre(colDish, colFood);
  const sumSnackFibre = sumFibre(colDish, colFood);
  const sumCollazioneZuccheri = sumZuccheri(colDish, colFood);
  const sumPranzoZuccheri = sumZuccheri(colDish, colFood);
  const sumCenaZuccheri = sumZuccheri(colDish, colFood);
  const sumSnackZuccheri = sumZuccheri(colDish, colFood);
  const sumCollazioneWeight = sumWeight(colDish, colFood);
  const sumPranzoWeight = sumWeight(colDish, colFood);
  const sumCenaWeight = sumWeight(colDish, colFood);
  const sumSnackWeight = sumWeight(colDish, colFood);

  const totalEnergy =
    sumCollazioneEnergy + sumPranzoEnergy + sumCenaEnergy + sumSnackEnergy;
  const totalCarbs =
    sumCollazioneCarbs + sumPranzoCarbs + sumCenaCarbs + sumSnackCarbs;
  const totalProte =
    sumCollazioneProte + sumPranzoProte + sumCenaProte + sumSnackProte;
  const totalGras =
    sumCollazioneGras + sumPranzoGras + sumCenaGras + sumSnackGras;
  const totalFibre =
    sumCollazioneFibre + sumPranzoFibre + sumCenaFibre + sumSnackFibre;
  const totalZuccheri =
    sumCollazioneZuccheri +
    sumPranzoZuccheri +
    sumCenaZuccheri +
    sumSnackZuccheri;
  const totalWeight =
    sumCollazioneWeight + sumPranzoWeight + sumCenaWeight + sumSnackWeight;

  // const rnd = (nr) => Math.round(+nr);
  const plusTenPercent = (nr) => {
    const num = parseInt(nr);
    return num * 0.1 + num;
  };

  const calcPercentage = (pr, nr) => {
    return (+nr * 100) / pr;
  };

  const activateRefeedDay = () => {
    const options = personalDish.options;
    api
      .post("/profile/me_change_personas_dish", {
        json: {
          ...originalPersonalDish,
          options: {
            ...options,
            lastUpdate: moment().format("YYYY-MM-DD"),
            used: +options.used + 1,
          },
        },
      })
      .then((response) => {
        console.log(response.data);
        dispatch(setPersonalDishLoading(true));
        dispatch(fetchPersonalDish());
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="container pb-4">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      {location.pathname === "/percorso/alimentazione" && (
        <div>
          <Card>
            <div
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => setCalendarOpen(!calendarOpen)}
            >
              <div className="d-flex align-items-baseline width-33">
                <p className="date-string date-string-day me-2">
                  {moment(selectedDay).format("D")}
                </p>
                <p className=" date-string date-string-month">
                  {moment(selectedDay).format("MMMM YYYY")}
                </p>
              </div>
              <div className="width-33 d-flex justify-content-center">
                <div className="menu-label">
                  <p className="menu-label-text">Menu Giornaliero</p>
                </div>
              </div>
              <div className="width-33 d-flex justify-content-end">
                <BiCalendar size={30} color={Colors.primary} />
              </div>
            </div>
            {calendarOpen && (
              <div className="calendar-wrapper d-flex justify-content-center">
                <Calendar
                  onChange={(value, event) => {
                    setSelectedDay(moment(value).format("YYYY-MM-DD"));
                    dispatch(
                      fetchMenuByDate(moment(value).format("YYYY-MM-DD"))
                    );
                    setCalendarOpen(false);
                  }}
                  locale="it-IT"
                  showNeighboringMonth={false}
                  next2Label={null}
                  prev2Label={null}
                  defaultView="month"
                  value={selectedDay}
                />
              </div>
            )}
          </Card>
          <Card style={{ backgroundColor: strategyCardColor }}>
            <div className="d-flex justify-content-between">
              <p className="heading">Strategia Alimentare</p>
              <IntroAlimentazioneModal
                openModal={
                  <div className="d-flex align-items-center allenamenti-video-intro">
                    <p className="allenamenti-video-text">Video Introduzione</p>
                    <IoPlayOutline size={25} color={Colors.primary} />
                  </div>
                }
              />
            </div>
            <div className="d-flex align-items-baseline">
              <p className="sub-heading">{personalDish?.strategies}</p>
            </div>
            <div className="row">
              <div className="col-xl-10 col-lg-12">
                <div className="d-flex justify-content-center">
                  <div className="progress-wrapper">
                    <div className="text-center">
                      <p className="macro-name">carboidrati</p>
                    </div>
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_carbo,
                        roundNumber(totalCarbs)
                      )}
                      text={`${roundNumber(totalCarbs)}`}
                      color={
                        roundNumber(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        roundNumber(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.greenLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        roundNumber(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="consigliati">
                        consigliati {personalDish?.macro_carbo}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                  <div className="text-center">
                      <p className="macro-name">grassi</p>
                    </div>
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_grasi,
                        roundNumber(totalGras)
                      )}
                      text={`${roundNumber(totalGras)}`}
                      color={
                        roundNumber(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        roundNumber(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.redLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        roundNumber(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="consigliati">
                        consigliati {personalDish?.macro_grasi}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                  <div className="text-center">
                      <p className="macro-name">proteine</p>
                    </div>
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_prote,
                        roundNumber(totalProte)
                      )}
                      text={`${roundNumber(totalProte)}`}
                      color={
                        roundNumber(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        roundNumber(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.redLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        roundNumber(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="consigliati">
                        consigliati {personalDish?.macro_prote}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                  <div className="text-center">
                      <p className="macro-name">KCAL</p>
                    </div>
                    <ProgressCircle
                      value={100}
                      text={`${roundNumber(totalEnergy)}`}
                      color={Colors.primary}
                      textColor={Colors.primary}
                    />
                    <div className="progress-details text-center">
                      <p className="consigliati">
                        consigliati {personalDish?.macro_kcal}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                  <div className="text-center">
                      <p className="macro-name">zuccheri</p>
                    </div>
                    <ProgressCircle
                      value={100}
                      text={`${roundNumber(totalZuccheri)}`}
                      color={Colors.primary}
                      textColor={Colors.primary}
                    />
                  </div>
                  <div className="progress-wrapper">
                  <div className="text-center">
                      <p className="macro-name">fibre</p>
                    </div>
                    <ProgressCircle
                      value={100}
                      text={`${roundNumber(totalFibre)}`}
                      color={Colors.primary}
                      textColor={Colors.primary}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-12 d-flex flex-xl-column justify-content-between ">
                <div className="generate-wrapper d-flex justify-content-xl-end">
                  {personalDish?.options &&
                    personalDish.strategies === "Metodo Misto" &&
                    +personalDish?.options?.used <
                      +personalDish?.options?.freeDay &&
                    !refeedDay && (
                      <Button
                        type="outline"
                        name="Attiva il giorno di ricarica"
                        style={{ width: 200 }}
                        textStyle={{ textAlign: "center" }}
                        onClick={() => {
                          setShowPopup({
                            visible: true,
                            heading: "ATTENZIONE",
                            message:
                              "Stai per attivare il tuo Giorno di ricarica, sei sicura di voler procedere?",
                            button: "SI",
                            onClose: () => setShowPopup({ visible: false }),
                            onClick: () => {
                              activateRefeedDay();
                              setShowPopup({ visible: false });
                            },
                          });
                        }}
                      />
                    )}
                </div>
                {customCategoryMenuItem && (
                  <div className="generate-wrapper d-flex justify-content-xl-end">
                    <Button
                      style={{ width: 200 }}
                      name={
                        generatedLoading ? (
                          <ClipLoader
                            color={Colors.white}
                            loading={generatedLoading}
                            size={20}
                          />
                        ) : (
                          "Genera Menu"
                        )
                      }
                      type={"primary"}
                      onClick={() => {
                        setShowPopup({
                          visible: true,
                          heading: "ATTENZIONE",
                          message:
                            "Sei sicuro di voler aggiornare il tuo menu? Tutti i piatti inseriti saranno cambiati.",
                          button: "OK",
                          onClose: () => setShowPopup({ visible: false }),
                          onClick: () => {
                            setGeneratedLoading(true);
                            customCategoryMenuItem === "Personalizzato"
                              ? generateMenu()
                              : generateCustomMenu();
                            setShowPopup({ visible: false });
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center mb-2">
                <div
                  className="filter-icon d-flex justify-content-center align-items-center cursor-pointer"
                  onClick={() => setFilterVisible(!filterVisible)}
                >
                  <BsFilterLeft size={22} color={"white"} />
                </div>
                <p className="heading heading-card mb-2 mt-xl-1 mt-lg-3 mt-md-3">
                  Seleziona la categoria del menu
                </p>
              </div>
              <div className="d-flex flex-wrap">
                {customCategoryMenu.map((item) => (
                  <div
                    key={item}
                    onClick={() => setCustomCategoryMenuItem(item)}
                    className={
                      customCategoryMenuItem === item
                        ? "filter-itemActive"
                        : "filter-item"
                    }
                  >
                    <p
                      className={
                        customCategoryMenuItem === item
                          ? "filter-itemTextActive"
                          : "filter-itemText"
                      }
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {filterVisible && (
              <div>
                <p className="heading heading-card mb-2 mt-xl-1 mt-lg-3 mt-md-3">
                  Escludi Alergeni
                </p>
                <div className="d-flex">
                  <Filter />
                </div>
              </div>
            )}
          </Card>
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Colazione</p>
                  <div className="d-flex">
                    <p className="macros-items">
                      Kcal {fixOne(sumCollazioneEnergy)}
                    </p>
                    <p className="macros-items">
                      Carb {fixOne(sumCollazioneCarbs)}
                    </p>
                    <p className="macros-items">
                      Grassi {fixOne(sumCollazioneGras)}
                    </p>
                    <p className="macros-items">
                      Proteine {fixOne(sumCollazioneProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.collazione.length > 0 &&
                    menuDish.collazione.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.T_carboidrati)}
                              gras={fixOne(item.T_grassi)}
                              prot={fixOne(item.T_proteine)}
                              labelText={`${fixOne(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "collazione",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.collazione.length > 0 &&
                    menuFood.collazione.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.carboidrati)}
                              gras={fixOne(item.grassi)}
                              prot={fixOne(item.proteine)}
                              labelText={`${fixOne(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "collazione",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("collazione"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Pranzo</p>
                  <div className="d-flex">
                    <p className="macros-items">
                      Kcal {fixOne(sumPranzoEnergy)}
                    </p>
                    <p className="macros-items">
                      Carb {fixOne(sumPranzoCarbs)}
                    </p>
                    <p className="macros-items">
                      Grassi {fixOne(sumPranzoGras)}
                    </p>
                    <p className="macros-items">
                      Proteine {fixOne(sumPranzoProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.pranzo.length > 0 &&
                    menuDish.pranzo.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.T_carboidrati)}
                              gras={fixOne(item.T_grassi)}
                              prot={fixOne(item.T_proteine)}
                              labelText={`${fixOne(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "pranzo",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.pranzo.length > 0 &&
                    menuFood.pranzo.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.carboidrati)}
                              gras={fixOne(item.grassi)}
                              prot={fixOne(item.proteine)}
                              labelText={`${fixOne(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "pranzo",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("pranzo"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Cena</p>
                  <div className="d-flex">
                    <p className="macros-items">Kcal {fixOne(sumCenaEnergy)}</p>
                    <p className="macros-items">Carb {fixOne(sumCenaCarbs)}</p>
                    <p className="macros-items">Grassi {fixOne(sumCenaGras)}</p>
                    <p className="macros-items">
                      Proteine {fixOne(sumCenaProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.cena.length > 0 &&
                    menuDish.cena.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.T_carboidrati)}
                              gras={fixOne(item.T_grassi)}
                              prot={fixOne(item.T_proteine)}
                              labelText={`${fixOne(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "cena",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.cena.length > 0 &&
                    menuFood.cena.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.carboidrati)}
                              gras={fixOne(item.grassi)}
                              prot={fixOne(item.proteine)}
                              labelText={`${fixOne(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "cena",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("cena"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Snack</p>
                  <div className="d-flex">
                    <p className="macros-items">
                      Kcal {fixOne(sumSnackEnergy)}
                    </p>
                    <p className="macros-items">Carb {fixOne(sumSnackCarbs)}</p>
                    <p className="macros-items">
                      Grassi {fixOne(sumSnackGras)}
                    </p>
                    <p className="macros-items">
                      Proteine {fixOne(sumSnackProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.snack.length > 0 &&
                    menuDish.snack.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.T_carboidrati)}
                              gras={fixOne(item.T_grassi)}
                              prot={fixOne(item.T_proteine)}
                              labelText={`${fixOne(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "snack",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.snack.length > 0 &&
                    menuFood.snack.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fixOne(item.carboidrati)}
                              gras={fixOne(item.grassi)}
                              prot={fixOne(item.proteine)}
                              labelText={`${fixOne(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "snack",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("snack"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      <Outlet />
    </div>
  );
}
