import React from "react";
import Card from "./Card";
import { FaAppStoreIos, FaGooglePlay } from "react-icons/fa";
import Colors from "../constants/Colors";

const icon = require("../assets/icon-fespa.png");

export default function AppOnly() {
  return (
    <div className="container appOnly">
      <div className="appOnly-wrapper">
        <Card style={{ backgroundColor: Colors.primaryLightShadow }}>
          <div className="appOnly-image-wrapper">
            <img
              src={icon}
              alt="Icon Image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <p className="heading text-center mt-3">
            Per accedere al tuo profilo da cellulare devi scaricare la nostra
            applicazione. Contatta il tuo Consulente e Coach di riferimento per
            scoprirne di più.
          </p>
          <div className="d-flex justify-content-evenly mt-4 mb-4">
            <div className="appOnly-icons">
              <FaAppStoreIos size={50} color={Colors.primary} />
            </div>
            <div className="appOnly-icons">
              <FaGooglePlay size={50} color={Colors.primary} />
            </div>
          </div>
          <p className="heading heading-card text-center">
            Se non sei ancora un nostro cliente ti invitiamo di lasciare i tuoi
            dati al seguente <a href="https://metodofespa-app.com/" target='_blank' style={{color: Colors.primary}}>link </a> e sarai contattato da un nostro Consulente
          </p> 
        </Card>
      </div>
    </div>
  );
}
