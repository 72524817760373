import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function ProgressCircle(props) {
  return <CircularProgressbar value={props.value} text={props.text} backgroundPadding={1} styles={{
    path: {
      stroke: `${props.color}`,
    },
    trail: {
      stroke: `${props.shadowColor}`
    },
    text: {
      fill: `${props.textColor}`,
      fontSize: 18,
    }
  }}/>
}
