import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAllergyFilter,
  setAllergyTrigger,
  setVeganFilter,
} from "../redux/filter";

export default function Filter() {
  const allergyFilter = useSelector((state) => state.filter.allergyFilter);
  const allergyList = Object.entries(allergyFilter)
    .slice(0, 13)
    .map((entry) => entry[0]);

  return (
    <div className="d-flex flex-wrap">
      {allergyList.map((el) => (
        <Ingredients item={el} key={el} />
      ))}
    </div>
  );
}

export const Ingredients = (props) => {
  const dispatch = useDispatch();
  const allergyFilter = useSelector((state) => state.filter.allergyFilter);
  const isActive = allergyFilter[props.item];

  return (
    <div
      onClick={() => {
        dispatch(
          setAllergyFilter({ item: props.item, value: isActive ? 0 : 1 })
        );
      }}
    >
      <div className={isActive ? "filter-itemActive " : "filter-item"}>
        <p className={isActive ? "filter-itemTextActive " : "filter-itemText"}>
          {props.item === "ceriali"
            ? "cereali"
            : props.item === "moluschi"
            ? "molluschi"
            : props.item}
        </p>
      </div>
    </div>
  );
};

export const VeganFilter = (props) => {
  const dispatch = useDispatch();
  const veganFilter = useSelector((state) => state.filter.veganFilter);
  const isActive = veganFilter[props.item];

  const onnivori = require("../assets/iconsFood/onnivori.png");
  const vegani = require("../assets/iconsFood/vegani.png");
  const vegetarianii = require("../assets/iconsFood/vegetariani.png");
  const pescetariani = require("../assets/iconsFood/pescetariani.png");

  const getIcon = () => {
    if (props.item === "onnivori") {
      return onnivori;
    } else if (props.item === "vegani") {
      return vegani;
    } else if (props.item === "vegetarianii") {
      return vegetarianii;
    } else if (props.item === "pescetariani") {
      return pescetariani;
    } else {
      console.log("error image filter");
    }
  };

  return (
    <div
      onClick={() => {
        dispatch(setVeganFilter({ item: props.item, value: 1 }));
        dispatch(setAllergyTrigger());
      }}
    >
      <div className={isActive ? "filter-itemActive" : "filter-item"}>
        <img
          src={getIcon()}
          alt={props.item}
          style={{ width: 20, height: 20, marginRight: 10 }}
        />
        <p className={isActive ? "filter-itemTextActive " : "filter-itemText"}>
          {props.item === "vegetarianii" ? "vegetariani" : props.item}
        </p>
      </div>
    </div>
  );
};
