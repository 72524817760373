import React from "react";
import IgnoreHeader from "../components/IgnoreHeader";
import {
  setTutorialCategory,
  setTutorialItem,
} from "../redux/tutorial";
import { useDispatch, useSelector } from "react-redux";
import Card from "../components/Card";
import { HiChevronRight } from "react-icons/hi";
import Colors from "../constants/Colors";

export default function Faq() {
  const dispatch = useDispatch();
  const howToList = useSelector((state) => state.tutorial.howToList);
  const whatsNewList = useSelector((state) => state.tutorial.whatsNewList);
  const tutorialCategory = useSelector(
    (state) => state.tutorial.tutorialCategory
  );
  const tutorialItem = useSelector((state) => state.tutorial.item);
  let parsedDescription;
  let description;

  if (Object.keys(tutorialItem).length > 0) {
    parsedDescription = JSON.parse(tutorialItem.content);
    description = parsedDescription.description;
  }

  // useEffect(() => {
  //   dispatch(setTutorialClear());
  //   dispatch(fetchTutorial());
  // }, []);

  return (
    <IgnoreHeader>
      <div className="container faq">
        <div className="row">
          <div className="col-3 col-xl-3 col-lg-6 col-md-6">
            <Card style={{ paddingTop: 0 }}>
              <div
                onClick={() => {
                  dispatch(setTutorialCategory("howTo"));
                  dispatch(setTutorialItem({}));
                }}
              >
                <FaqCard
                  heading="Come"
                  style={{
                    backgroundColor:
                      tutorialCategory === "howTo" && Colors.primaryLight,
                  }}
                  textStyle={{color: tutorialCategory === "howTo" && Colors.white, }}
                />
              </div>
              <div
                onClick={() => {
                  dispatch(setTutorialCategory("whatsNew"));
                  dispatch(setTutorialItem({}));
                }}
              >
                <FaqCard
                  heading={`Cosa c’è di nuovo?`}
                  style={{
                    backgroundColor:
                      tutorialCategory === "whatsNew" && Colors.primaryLight,
                  }}
                  textStyle={{color: tutorialCategory === "whatsNew" && Colors.white}}
                />
              </div>
            </Card>
          </div>
          <div className="col-3 col-xl-3 col-lg-6 col-md-6">
            {tutorialCategory && (
              <Card style={{ paddingTop: 0 }}>
                {tutorialCategory === "howTo" ? (
                  <div>
                    {howToList.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => dispatch(setTutorialItem(item))}
                      >
                        <FaqCard
                          heading={item.name}
                          description={'Come'}
                          style={{
                            backgroundColor:
                              item.id === tutorialItem?.id &&
                              Colors.primaryLight,
                          }}
                          textStyle={{color: item.id === tutorialItem?.id && Colors.white}}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {whatsNewList.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => dispatch(setTutorialItem(item))}
                      >
                        <FaqCard
                          heading={item.name}
                          description={`Cosa c’è di nuovo?`}
                          style={{
                            backgroundColor:
                              item.id === tutorialItem?.id &&
                              Colors.primaryLight,
                          }}
                          textStyle={{color: item.id === tutorialItem?.id && Colors.white}}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            )}
          </div>
          <div className="col-6 col-xl-6 col-lg-12 col-md-12">
            {Object.keys(tutorialItem).length > 0 && (
              <Card>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Card>
            )}
          </div>
        </div>
      </div>
    </IgnoreHeader>
  );
}

export const FaqCard = (props) => {
  return (
    <Card style={{ padding: 0, overflow: "hidden" }}>
      <div
        className="d-flex justify-content-between align-items-center faqCard"
        style={props.style}
      >
        <div>
          <p className="faqCard-heading" style={props.textStyle}>{props.heading}</p>
          {props.description && (
            <p className="faqCard-description">{props.description}</p>
          )}
        </div>
        <HiChevronRight size={25} style={props.textStyle} />
      </div>
    </Card>
  );
};
