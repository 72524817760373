import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api} from "../api/api";

export const fetchDish = createAsyncThunk(
  'dish/all',
  async function () {
    const response = await api.get('/dish/all')
    return response.data.dish
  }
)

export const fetchDishByCategory = createAsyncThunk(
  'dish/ByCategory',
  async function (category) {
    const response = await api.post('/dish/ByCategory', {category})
    return {
      list: response.data.dish,
      category
    }
  }
)

export const fetchDishCategory = createAsyncThunk(
  'dish/category',
  async function () {
    const response = await api.get('/dish/category')
    return response.data.category
  }
)

export const fetchCustomDish = createAsyncThunk(
  'dish/custom',
  async function () {
    const response = await api.get('/dish/my_all')
    return response.data.dish
  }
)

export const deleteCustomDish = createAsyncThunk(
  "dish/delete_dish",
  async function (id) {
    const response = await api.post("/dish/RemovePersonal", {
      id: id,
      type: "dish",
    });
    return response.data;
  }
);

const initialState = {
  list: [],
  category: [],
  loading: true,
  item: {},
  myCustomDish: []
};

export const dish = createSlice({
  name: "dish",
  initialState,
  reducers: {
    setDishItem: (state, action) => {
      state.item = action.payload
    },
    setDishLoading: (state, action) => {
      state.loading = action.payload
    },
    setCategory: (state, action) => {
      const findItem = state.category.find(it => it.item === action.payload)
      if (findItem) {
        findItem.selected =  !findItem.selected
      }
    },
  },
  extraReducers: {
    [fetchDish.fulfilled]: (state, action) => {
      state.list = action.payload
      state.loading = false
    },
    [fetchDishByCategory.fulfilled]: (state, action) => {
      state.list.push({
        type: action.payload.category,
        list: action.payload.list
      })
      state.loading = false
    },
    [fetchDishCategory.fulfilled]: (state, action) => {
      state.category = action.payload.map(item => {
        return {
          item,
          selected: false
        }
      })
      state.loading = false
    },
    [fetchCustomDish.fulfilled]: (state, action) => {
      state.myCustomDish = action.payload
    }
  },
});


export const {setDishLoading, setCategory} = dish.actions;
export default dish.reducer;
