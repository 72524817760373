import React from "react";
import Card from "./Card";
import ImageRender from "./ImageRender";
import Colors from "../constants/Colors";

export default function CheckFotoCard(props) {
  return (
    <Card>
      <p className="heading heading-card">{props.heading}</p>
      <div className="d-flex justify-content-between justify-content-md-evenly">
        <div className="parametri-image-wrapper">
          <ImageRender
            idImg={props.sourceLeft}
            label={"inizio percorso"}
            color={Colors.orange}
            labelWidth={130}
          />
        </div>
        {props.sourceMiddle && (
          <div className="parametri-image-wrapper">
          <ImageRender
            idImg={props.sourceMiddle}
            label={`Check ${props.beforeLastDate}`}
            labelWidth={130}
          />
        </div>
        )}
        {props.sourceLeft !== props.sourceRight && (
          <div className="parametri-image-wrapper">
            <ImageRender
              idImg={props.sourceRight}
              label={"ultimo check"}
              labelWidth={130}
            />
          </div>
        )}
      </div>
    </Card>
  );
}
