import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchAllergyFilter = createAsyncThunk(
  "filter/allergyFilter",
  async function () {
    const jsonValue = localStorage.getItem("allergyFilter");
    return jsonValue != null
      ? JSON.parse(jsonValue)
      : initialState.allergyFilter;
  }
);

export const fetchActiveAllergy = createAsyncThunk(
  "filter/activeAllergy",
  async function () {
    const jsonValue = localStorage.getItem("activeAllergy");
    return jsonValue != null
      ? JSON.parse(jsonValue)
      : initialState.activeAllergy;
  }
);

const initialState = {
  allergyFilter: {
    ceriali: 0,
    crostacei: 0,
    arachidi: 0,
    soia: 0,
    frutta: 0,
    senape: 0,
    sesamo: 0,
    solfiti: 0,
    sedano: 0,
    uova: 0,
    moluschi: 0,
    lupini: 0,
    latte: 0,
    onnivori: 0,
    vegetarianii: 0,
    vegani: 0,
    pescetariani: 0,
  },
  activeAllergy: {},
  veganFilter: {
    onnivori: 1,
    vegetarianii: 0,
    vegani: 0,
    pescetariani: 0,
  },
  activeVegan: {},
  allergyTrigger: 0
};

export const filter = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setAllergyFilter: (state, action) => {
      state.allergyFilter[action.payload.item] = action.payload.value;
      if (action.payload.value === 1) {
        state.activeAllergy[action.payload.item] = action.payload.value;
      } else {
        delete state.activeAllergy[action.payload.item]
      }
      storeAllergyFilter(state.allergyFilter);
      storeActiveAllergy(state.activeAllergy);
    },
    setAllergyTrigger: (state, action) => {
      state.allergyTrigger += 1
    },
    setVeganFilter: (state, action) => {
      state.veganFilter['onnivori'] = 0;
      state.veganFilter['vegetarianii'] = 0;
      state.veganFilter['vegani'] = 0;
      state.veganFilter['pescetariani'] = 0;
      state.activeVegan = {};

      state.veganFilter[action.payload.item] = action.payload.value;
      state.activeVegan[action.payload.item] = action.payload.value;
    },
  },
  extraReducers: {
    [fetchAllergyFilter.fulfilled]: (state, action) => {
      state.allergyFilter = action.payload;
    },
    [fetchActiveAllergy.fulfilled]: (state, action) => {
      state.activeAllergy = action.payload;
    },
  },
});

const storeAllergyFilter = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem("allergyFilter", jsonValue);
  } catch (e) {
    // saving error
  }
};

const storeActiveAllergy = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem("activeAllergy", jsonValue);
  } catch (e) {
    // saving error
  }
};

export const { setAllergyFilter, setAllergyTrigger, setVeganFilter } = filter.actions;
export default filter.reducer;
