import React from "react";
import CookiePolicyModal from "./CookiePolicyModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="footer-items">
        <p className="footer-items-text footer-copyright">FESPA 2022 ©</p>
      </div>
      <div className="footer-items">
        <CookiePolicyModal
          openModal={<p className="footer-items-text">Cookie Policy</p>}
        />
      </div>
      <div className="footer-items">
        <PrivacyPolicyModal
          openModal={<p className="footer-items-text">Privacy Policy</p>}
        />
      </div>
    </div>
  );
}
