import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import bg from "../assets/bg-fespa.webp";

export default function Startup() {

  return (
    <div className="login">
      <img src={bg} alt="Fespa Background" className="login-bg" />
      <div className="login-wrapper">
        <p className="login-logo">fespa</p>
        <ClipLoader color={"white"} loading={true} size={30} />
      </div>
    </div>
  );
}
