import React, { useState, useRef, useEffect } from "react";
import { HiX } from "react-icons/hi";
import { useSelector } from "react-redux";
import Card from "./Card";
import Label from "./Label";
import VideoPlayer from "./VideoPlayer";
import Colors from "../constants/Colors";

export default function IntroAlimentazioneModal(props) {
  const personalDish = useSelector(state => state.profile.personalDish);
  const modalRef = useRef(null);

  const handleClose = () => {
    // modalRef.current.setAttribute('data-bs-dismiss', 'modal');
    modalRef.current.click();
  }      

  return (
    <div className="introAllenamenti">
      <a
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#introAlimentazioneModal"
      >
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="introAlimentazioneModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable introAllenamenti-modal-dialog">
          <div className="modal-content">
            <div className="modal-header introAllenamenti-modal-header justify-content-center">
              <button
                type="button"
                className="introAllenamenti-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="introAllenamenti-title">Video Introduzione</p>
            </div>
            <div className="modal-body">
              <div className="introAllenamenti-body-wrapper">
                <div className="d-flex align-items-center">
                  <Label
                    labelText={personalDish.strategies}
                    labelColor={Colors.green}
                  />
                </div>
                <Card style={{ padding: 0 }}>
                  <VideoPlayer
                    link={personalDish.aboutInst.link}
                  />
                </Card>
                <Card>
                  <p className="heading heading-card">Descrizione</p>
                  <p className="contentText">{personalDish.testo_descpr}</p>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default function IntroAlimentazioneModal(props) {
//   const [isOpen, setIsOpen] = useState(false);
//   const personalDish = useSelector((state) => state.profile.personalDish);
//   const modalRef = useRef(null);

//   function handleClose() {
//     // setIsOpen(false);
//     // modalRef.current.classList.remove("show");
//     // modalRef.current.setAttribute('data-bs-toggle', 'modal');
//     // modalRef.current.setAttribute('data-bs-target', '#introAlimentazioneModal')
//     modalRef.current.setAttribute('data-bs-dismiss', 'modal')
//     // modalRef.current.style.display = "none";
//   }


//   return (
//     <div className="introAllenamenti">
//       <button
//         type="button"
//         data-bs-toggle="modal"
//         data-bs-target="#introAlimentazioneModal"
//         // onClick={() => setIsOpen(true)}
//       >
//         {props.openModal}
//       </button>
//       <div
//         className={`modal fade overlay-dark`} //${isOpen ? "show" : ""}`}
//         id="introAlimentazioneModal"
//         tabIndex="-1"
//         aria-hidden="true"
//         ref={modalRef}
//       >
//         <div className="modal-dialog modal-dialog-scrollable introAllenamenti-modal-dialog">
//           <div className="modal-content">
//             <div className="modal-header introAllenamenti-modal-header justify-content-center">
//               <button
//                 type="button"
//                 className="introAllenamenti-btn-close"
//                 aria-label="Close"
//                 onClick={handleClose}
//               >
//                 <HiX size={30} />
//               </button>
//               <p className="introAllenamenti-title">Video Introduzione</p>
//             </div>
//             <div className="modal-body">
//               <div className="introAllenamenti-body-wrapper">
//                 <div className="d-flex align-items-center">
//                   <Label
//                     labelText={personalDish.strategies}
//                     labelColor={Colors.green}
//                   />
//                 </div>
//                 <Card style={{ padding: 0 }}>
//                   <VideoPlayer link={personalDish.aboutInst.link} />
//                 </Card>
//                 <Card>
//                   <p className="heading heading-card">Descrizione</p>
//                   <p className="contentText">{personalDish.testo_descpr}</p>
//                 </Card>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
