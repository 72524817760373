import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import { api } from "../api/api";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAnamnesiId,
  fetchAnamnesiQuestion,
  setAnamnesiSummary,
  clearAnamnesiSummary,
  fetchAnamnesiList
} from "../redux/profile";
import Loader from "../components/Loader";
import Button from "../components/Button";
import RadioButton from "../components/RadioButton";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import { FiEdit3 } from "react-icons/fi";
import Colors from "../constants/Colors";

export default function Anamnesi() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anamnesiSummary = useSelector((state) => state.profile.anamnesiSummary);
  const [finished, setFinished] = useState(false);
  const [edit, setEdit] = useState(false);
  const [question, setQuestion] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [answer, setAnswer] = useState({
    sessions: "",
    question_id: "",
    answered: null,
  });
  const data = useSelector((state) => state.profile.anamnesiQuestion);
  const dataLoading = useSelector(
    (state) => state.profile.anamnesiQuestionLoading
  );
  const idSession = useSelector((state) => state.profile.anamnesiId);
  const idSessionLoading = useSelector(
    (state) => state.profile.anamnesiIdLoading
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  useEffect(() => {
    dispatch(fetchAnamnesiQuestion());
    dispatch(fetchAnamnesiId());
    dispatch(clearAnamnesiSummary());
    setShowPopup({
      visible: true,
      heading: "ATTENZIONE",
      message:
        "Stai per compilare un questionario. Affinché esso sia compilato correttamente, assicurati di avere almeno 10 minuti per rispondere in modo dettagliato alle domande etc.",
      button: "Continua",
      onClose: () => {
        setShowPopup({ visible: false });
        navigate(-1);
      },
      onClick: () => setShowPopup({ visible: false }),
    });
  }, []);

  const questionId = data.map((el) => el.id);
  const questionObject = data.find((el) => el.id === questionId[question]);

  const insertAnswer = () => {
    dispatch(
      setAnamnesiSummary({
        questionCount: question,
        id: answer.question_id,
        sessionId: answer.sessions,
        answer: answer.answered,
        questionTitle: questionObject.title,
      })
    );
    api
      .post("/profile/insert_answerd", {
        ...answer,
        answered: JSON.stringify(answer),
      })
      .then((response) =>
        console.log("response submitted", questionId[question])
      )
      .catch((err) => console.log("ERR**", err));
  };

  useEffect(() => {
    if (!answer || !answer.sessions) return;

    if (!answer.question_id)
      return setShowPopup({
        visible: true,
        heading: "ERROR",
        message: "Question ID is required!",
        button: "Try Again",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => setShowPopup({ visible: false }),
      });
    if (!answer.sessions)
      return setShowPopup({
        visible: true,
        heading: "ERROR",
        message: "Session is required!",
        button: "Try Again",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => setShowPopup({ visible: false }),
      });

    const findItem = data.find((el) => el.id === questionId[question]);
    if (!findItem) return null;

    const type = findItem.type;
    if (type === "text")
      if (!answer.answered)
        return setShowPopup({
          visible: true,
          heading: "ATTENZIONE",
          message: "Rispondi alla domanda per andare avanti",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        });
    if (type === "select")
      if (!answer.answered)
        return setShowPopup({
          visible: true,
          heading: "ATTENZIONE",
          message: "Rispondi alla domanda per andare avanti",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        });

    insertAnswer();
    setQuestion(question + 1);
    setInputValue("");
    setSelectedOption(null);
    if (question === data.length - 1 || edit) {
      //updateAnamnesis();
      setFinished(true);
    }
  }, [answer]);

  const updateAnamnesis = () => {
    api
      .post("/profile/update_anamnesis", {
        id: idSession,
        status: "Moderation",
      })
      .then((response) => {
        console.log("anamnesis updated", response.data);
        dispatch(fetchAnamnesiList());
        // setQuestion(0);
        // dispatch(fetchAnamnesiQuestion());
        // dispatch(fetchAnamnesiId());
        setShowPopup({
          visible: true,
          heading: "GRAZIE",
          message: "Il tuo questionario è stato inviato correttamente.",
          button: "OK",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            navigate("../alimentazione");
            setShowPopup({ visible: false });
          },
        });
      })
      .catch((err) => {
        setShowPopup({
          visible: true,
          heading: "ERRORE",
          message: "Riprova piu tardi",
          button: "OK",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            navigate("../alimentazione");
            setShowPopup({ visible: false });
          },
        });
        console.log("anamnesis error", err.response);
      });
  };

  const setOption = (e) => {
    if (!e.label) return;
    setSelectedOption(e.label);
  };

  const getRadios = () => {
    const findElem = data.find((el) => el.id === questionId[question]);
    if (!findElem || !findElem?.options) return [];

    const options = findElem?.options;

    if (options && options !== "-") {
      const val = JSON.parse(options);

      if (!Array.isArray(val?.list)) return [];
      return val.list.map((item) => {
        return { label: item };
      });
    }
    return [];
  };

  const getType = () => {
    const findElem = data.find((el) => el.id === questionId[question]);
    if (!findElem || !findElem?.type) return "";
    return findElem.type;
  };

  if (dataLoading || idSessionLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={dataLoading || idSessionLoading} size={50} />
      </div>
    );
  }

  return (
    <div className="container anamnesi">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <Card>
        <div className="anamnesi-wrapper">
          {!finished ? (
            <Card>
              <p className="heading text-center">
                Domanda {question + 1} /{data.length}
              </p>
              <div className="anamnesi-question mt-4">
                <p className="anamnesi-question-text">
                  {data.map((el) => el.id === questionId[question] && el.title)}
                </p>
              </div>
              {getType() === "select" && (
                <div>
                  {getRadios().map((item, index) => (
                    <RadioButton
                      key={index}
                      id={index}
                      value={item.label}
                      label={item.label}
                      checked={inputValue === item.label}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  ))}
                </div>
              )}
              <div className="anamnesi-response mt-5">
                <p className="anamnesi-response-text">La tua risposta*</p>
                <input
                  onChange={(e) => setInputValue(e.target.value)}
                  className="anamnesi-response-input"
                  placeholder="digita qui la tua risposta"
                  value={inputValue}
                  autoCorrect
                  autoFocus
                />
              </div>
              <div className="mt-5 d-flex">
                {question > 0 && !edit && (
                  <Button
                    type="outline"
                    name="Precedente"
                    onClick={() =>
                      question > 0 ? setQuestion(question - 1) : null
                    }
                    style={{ marginRight: 5 }}
                  />
                )}
                <Button
                  name={
                    question === data.length - 1 && !edit
                      ? "Controlla e invia"
                      : edit
                      ? "Salva"
                      : "Prossima"
                  }
                  type={"primary"}
                  onClick={() => {
                    let userAnswer;
                    if (selectedOption) {
                      if (
                        selectedOption === "Altro" ||
                        selectedOption === "Text"
                      ) {
                        userAnswer = inputValue;
                      } else {
                        userAnswer = selectedOption;
                      }
                    } else {
                      userAnswer = inputValue;
                    }
                    setAnswer({
                      sessions: idSession,
                      question_id: questionId[question],
                      answered: userAnswer,
                    });
                  }}
                  style={{ marginLeft: 5 }}
                />
              </div>
            </Card>
          ) : (
            <div>
              {anamnesiSummary.length > 0 &&
                anamnesiSummary.map((item, index) => (
                  <div key={index}>
                    <Card>
                      <div className="d-flex justify-content-between">
                        <p className="anamnesi-question-text">
                          {item.questionTitle}
                        </p>
                        <FiEdit3
                          size={20}
                          color={Colors.primary}
                          onClick={() => {
                            setFinished(false);
                            setEdit(true);
                            setQuestion(item.questionCount);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="anamnesi-response-text">La tua risposta*</p>
                      <p className="anamnesi-response-answer mt-3">{item.answer}</p>
                    </Card>
                  </div>
                ))}
              <div className="mt-5">
                <Button type="primary" name="Invia" onClick={updateAnamnesis} />
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
