import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const fetchNotifications = createAsyncThunk(
  "client/notifications",
  async function () {
    const { data } = await api.post("/client/getNewNotf");
    return data.list;
  }
);

export const fetchStorageListNotifications = createAsyncThunk(
  "client/listNotifications",
  async function () {
    const jsonValue = await localStorage.getItem("listNotifications");
    return jsonValue != null
      ? JSON.parse(jsonValue)
      : initialState.listNotifications;
  }
);

export const fetchStorageNewNotifications = createAsyncThunk(
  "client/newNotifications",
  async function () {
    const jsonValue = await localStorage.getItem("newNotifications");
    return jsonValue != null
      ? JSON.parse(jsonValue)
      : initialState.newNotifications;
  }
);

export const fetchTokenExpo = createAsyncThunk(
  "profile/getTokenExpo",
  async function () {
    const response = await api.get("/profile/get_token_expo");
    return response.data.data;
  }
);

export const changeTokenExpo = createAsyncThunk(
  "client/changeTokenExpo",
  async function (listToken) {
    api
      .post("/client/change_token_expo", {
        token_expo: JSON.stringify(listToken),
      })
      .then((response) => {})
      .catch((err) => console.log("post token error"));
  }
);

const checkItem = (arr, item) => {
  const ids = [];
  arr.map((el) => {
    ids.push(el.id);
  });

  const index = ids.indexOf(item.id);

  arr.splice(index, 1);
};

const initialState = {
  listNotifications: [],
  newNotifications: [],
  notificationLoading: true,
  listLoading: true,
  newLoading: true,
  tokenExpo: null,
  tokenExpoLoading: true,
};

export const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    moveNotification: (state, action) => {
      state.listNotifications.push(action.payload);
      storeListNotifications(state.listNotifications);
    },
    deleteNewNotification: (state, action) => {
      checkItem(state.newNotifications, action.payload);
      storeNewNotifications(state.newNotifications);
    },
    deleteListNotification: (state, action) => {
      checkItem(state.listNotifications, action.payload);
      storeListNotifications(state.listNotifications);
    },
  },
  extraReducers: {
    [fetchNotifications.fulfilled]: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        action.payload.map((el) => state.newNotifications.push(el));
      }
      if (action.payload && action.payload.length > 0) {
        storeNewNotifications(state.newNotifications);
      }
      state.notificationLoading = false;
    },
    [fetchStorageListNotifications.fulfilled]: (state, action) => {
      state.listNotifications = action.payload;
      state.listLoading = false;
    },
    [fetchStorageNewNotifications.fulfilled]: (state, action) => {
      state.newNotifications = action.payload;
      state.newLoading = false;
    },
    [fetchTokenExpo.fulfilled]: (state, action) => {
      state.tokenExpo = action.payload;
      state.tokenExpoLoading = false;
    },
  },
});

const storeListNotifications = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem("listNotifications", jsonValue);
  } catch (e) {
    // saving error
  }
};

const storeNewNotifications = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem("newNotifications", jsonValue);
  } catch (e) {
    // saving error
  }
};

export const {
  moveNotification,
  deleteNewNotification,
  deleteListNotification,
} = notifications.actions;
export default notifications.reducer;
