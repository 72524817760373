import React, { useState, useRef, useEffect } from "react";
import Card from "../components/Card";
import ImageRender from "../components/ImageRender";
import {
  setSessione,
  setSessioneLoading,
  setSessioneActive,
  fetchVideoCourseProgress,
} from "../redux/videoCourses";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Label from "../components/Label";
import Colors from "../constants/Colors";
import ProductVideoCard from "../components/ProductVideoCard";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  HiChevronLeft,
  HiOutlineArrowCircleLeft,
  HiOutlineArrowCircleRight,
} from "react-icons/hi";
import { api } from "../api/api";

export default function MyCourseDetails() {
  const scrollRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course = useSelector((state) => state.videoCourse.item);
  const videoCourseLoading = useSelector((state) => state.videoCourse.loading);
  const videoCourseType = useSelector((state) => state.videoCourse.courseType);
  const sessioneActive = useSelector(
    (state) => state.videoCourse.sessioneActive.item
  );
  const [scroll, setScroll] = useState(0);
  const videoCourseCount = useSelector(
    (state) => state.videoCourse.videoCourseCount
  );
  const videoCourseIds = useSelector(
    (state) => state.videoCourse.videoCourseIds
  );

  useEffect(() => {
    if (Object.keys(course).length > 0) {
      dispatch(fetchVideoCourseProgress(course.id));
    }
  }, [course]);

  useEffect(() => {
    if (Object.keys(course).length > 0){
      const percentage = parseInt(
        (videoCourseCount.length * 100) / videoCourseIds.length
      );
      api
        .post("/video_course/add_percent", {
          course: course.id,
          percent: percentage,
        })
        .then((res) => console.log(res.data, 'response from posting percentage'))
        .catch((err) => console.log(err));
    }
  }, [videoCourseCount]);

  useEffect(() => {
    const divElement = scrollRef.current;
    if (divElement) {
      divElement.scroll({ left: scroll, behavior: "smooth" });
    }
  }, [scroll]);

  if (videoCourseLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={videoCourseLoading} size={50} />
      </div>
    );
  }

  if (!course) return <p style={{ padding: 50 }}>No course found</p>;

  if (course?.json_object.length === 0) {
    return <p style={{ padding: 50 }}>An ERROR occured!</p>;
  }

  const sessione = course?.json_object;

  return (
    <div className="container courseDetails pb-3">
      <Card>
        <div className="d-flex align-items-center">
          <HiChevronLeft color={Colors.primary} size={20} />
          <p
            className="courseDetails-nav"
            onClick={() => navigate("/percorso/disponibili")}
          >
            Disponibili
          </p>
          {(location.pathname ===
            "/percorso/disponibili/myCourseDetails/courseSection" ||
            location.pathname ===
              "/percorso/disponibili/myCourseDetails/courseSection/sectionVideo") && (
            <p
              className="courseDetails-nav"
              onClick={() => navigate("/percorso/disponibili/myCourseDetails")}
            >
              / {course.name}
            </p>
          )}
          {sessioneActive &&
            location.pathname ===
              "/percorso/disponibili/myCourseDetails/courseSection/sectionVideo" && (
              <p
                className="courseDetails-nav"
                onClick={() =>
                  navigate(
                    "/percorso/disponibili/myCourseDetails/courseSection"
                  )
                }
              >
                / {sessioneActive.name}
              </p>
            )}
        </div>
        {location.pathname === "/percorso/disponibili/myCourseDetails" && (
          <div className="courseDetails-wrapper mt-2">
            <div className="d-flex align-items-center mb-3">
              <p className="heading me-3">{course.name}</p>
              <Label labelText={"Video Corso"} labelColor={Colors.primary} />
            </div>
            <Card style={{ padding: 0, overflow: "hidden", height: 430 }}>
              <ImageRender idImg={course.image} />
            </Card>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="d-flex">
                <div className="allenamenti-items">
                  <p className="allenamenti-items-text">Contenuti</p>
                  <p className="allenamenti-items-content">
                    {course.contenuti || 0}
                  </p>
                </div>
                <div className="allenamenti-items">
                  <p className="allenamenti-items-text">video</p>
                  <p className="allenamenti-items-content">
                    {course.count_video || 0}
                  </p>
                </div>
              </div>
            </div>
            <Card style={{ padding: 0 }}>
              <HiOutlineArrowCircleLeft
                size={30}
                color={Colors.primary}
                className="courseDetails-arrow courseDetails-arrow-left"
                onClick={() => setScroll(scroll - 250)}
              />
              <div
                className="d-flex p-3 pt-1"
                style={{ overflow: "scroll" }}
                ref={scrollRef}
              >
                {sessione.length > 0 &&
                  sessione.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (videoCourseType === "myItem") {
                          dispatch(setSessioneLoading(true));
                          dispatch(setSessione(sessione));
                          dispatch(
                            setSessioneActive({
                              item: item,
                              index: index,
                            })
                          );
                          navigate("courseSection");
                        }
                      }}
                    >
                      <ProductVideoCard
                        idImg={item.image}
                        label={"contenuti"}
                        name={item.name || ""}
                        video={`video ${index + 1}`}
                      />
                    </div>
                  ))}
              </div>
              <HiOutlineArrowCircleRight
                size={30}
                color={Colors.primary}
                className="courseDetails-arrow courseDetails-arrow-right"
                onClick={() => setScroll(scroll + 250)}
              />
            </Card>
            <Card>
              <p className="heading heading-card">Descrizione</p>
              <div dangerouslySetInnerHTML={{ __html: course.description }} />
            </Card>
          </div>
        )}
      </Card>

      <Outlet />
    </div>
  );
}
