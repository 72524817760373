import React from "react";
import Button from "./Button";

export default function CookiePopup(props) {
  return (
    <div className="cookiePopup">
      <div className="cookiePopup-wrapper">
        <p className="cookiePopup-title">
          Noi uttilizziamo cookie che usiamo per il marketing per potervi dare
          un’esperienza migliore uttilizzando il nostro prodotto.
        </p>
        <div className="d-flex">
          <div className="me-3">
            <Button
              type="outline"
              name="Manage Cookies"
              style={{ height: 40 }}
              onClick={props.onManage}
            />
          </div>
          <div>
            <Button
              type="primary"
              name="Accept All"
              style={{ height: 40 }}
              onClick={props.onAccept}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
