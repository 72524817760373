import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiX, HiOutlineHeart, HiHeart } from "react-icons/hi";
import Label from "./Label";
import Colors from "../constants/Colors";
import Card from "./Card";
import IngredientCard from "./IngredientCard";
import Button from "./Button";
import { setMenuDish } from "../redux/menu";
import { toggleFavoriteDish } from "../redux/favorites";
import ImageRender from "./ImageRender";
import DonePopup from "./DonePopup";
import { AnimatePresence } from "framer-motion";
import {fixOne} from '../helper/calc/fixOne'

export default function DishDetails(props) {
  const dispatch = useDispatch();
  const itemModalDish = useSelector((state) => state.menu.itemModalDish);
  const favoriteDish = useSelector(
    (state) => state.favorites.favorites.favoriteDish
  );
  const [showDonePopup, setShowDonePopup] = useState(false);

  let ingredients;

  if (Object.keys(itemModalDish).length > 0) {
    ingredients = JSON.parse(itemModalDish.gadients);
  }

  const idDish = [];

  if (favoriteDish && favoriteDish.length > 0) {
    favoriteDish.map((el) => {
      idDish.push(el.id);
    });
  }

  const popupTimeout = () => {
    setShowDonePopup(true);
    setTimeout(() => {
      setShowDonePopup(false);
    }, 1500);
  };

  return (
    <div className="dishDetails">
      <AnimatePresence>
        {showDonePopup && <DonePopup visible={showDonePopup} />}
      </AnimatePresence>
      <a
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#dishDetailsModal-${props.id}`}
      >
        {props.openModal}
      </a>

      <div
        className="modal fade overlay-dark"
        id={`dishDetailsModal-${props.id}`}
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable dishDetails-modal-dialog">
          <div className="modal-content">
            <div className="modal-header dishDetails-modal-header justify-content-center">
              <button
                type="button"
                className="dishDetails-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="dishDetails-title">
                Aggiungi il piatto nel tuo menu
              </p>
            </div>
            <div className="modal-body">
              <div className="container mt-4">
                <div className="d-flex align-items-center mb-4">
                  <p className="heading mb-0">{itemModalDish.name}</p>
                  <Label
                    labelText={`${fixOne(itemModalDish.T_energia || 0)} Kcal`}
                    labelColor={Colors.green}
                    labelWidth={80}
                    style={{ marginLeft: 10 }}
                  />
                </div>
                <div className="row">
                  <div className="col-xl-7 col-lg-7 col-md-12 mb-md-3">
                    <div className="dishDetails-image-wrapper">
                      <ImageRender
                        idImg={props.img}
                        initialName={itemModalDish.name}
                        bigText
                      />
                    </div>
                    <Card>
                      <p className="heading">Descrizione</p>
                      <p className="contentText">{itemModalDish.description}</p>
                    </Card>
                    <Card>
                      <p className="heading">Preparazione</p>
                      <p className="contentText">{itemModalDish.instruction}</p>
                    </Card>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-12">
                    <div className="dishDetails-items-wrapper d-flex justify-content-center">
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">carboidrati</p>
                        <p className="dishDetails-item-quantity">
                          {fixOne(itemModalDish.T_carboidrati)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">grassi</p>
                        <p className="dishDetails-item-quantity">
                          {fixOne(itemModalDish.T_grassi)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">proteine</p>
                        <p className="dishDetails-item-quantity">
                          {fixOne(itemModalDish.T_proteine)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">zuccheri</p>
                        <p className="dishDetails-item-quantity">
                          {fixOne(itemModalDish.T_zuccheri)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">fibre</p>
                        <p className="dishDetails-item-quantity">
                          {fixOne(itemModalDish.T_figre)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">peso</p>
                        <p className="dishDetails-item-quantity">
                          {fixOne(itemModalDish.T_weight)}
                        </p>
                      </div>
                    </div>
                    <Card>
                      <p className="heading heading-card">Ingredienti</p>
                      {ingredients?.length > 0 &&
                        ingredients.map((item, index) => (
                          <IngredientCard
                            key={index}
                            name={item.name || ""}
                            weight={`${fixOne(item.weight)}g`}
                            kcal={item.energia || 0}
                            carb={item.carboidrati || 0}
                            gras={item.grassi || 0}
                            prot={item.proteine || 0}
                          />
                        ))}
                    </Card>
                    <div className="mt-4">
                      <Button
                        name={
                          idDish?.includes(itemModalDish.id)
                            ? "Piatto Preferito"
                            : "Aggiungi ai Preferiti"
                        }
                        type={"outline"}
                        icon={
                          idDish?.includes(itemModalDish.id) ? (
                            <HiHeart size={25} />
                          ) : (
                            <HiOutlineHeart size={25} />
                          )
                        }
                        onClick={() =>
                          dispatch(toggleFavoriteDish(itemModalDish))
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <Button
                        name={
                          props.menu
                            ? "Aggiunto nel tuo menu"
                            : "Aggiungi al Menu"
                        }
                        type={"primary"}
                        onClick={() => {
                          if (!props.menu) {
                            dispatch(setMenuDish(itemModalDish));
                            popupTimeout();
                          } else {
                            return null;
                          }
                        }}
                        style={
                          props.menu && {
                            backgroundColor: Colors.primaryLightShadow,
                            opacity: 1,
                          }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
