import React from "react";
import Card from "./Card";
import { HiLockClosed } from "react-icons/hi";
import Button from "./Button";
import Colors from "../constants/Colors";

export default function ClosedContentCard(props) {
  return (
    <div className="closedContent">
      <Card>
        <div className="d-flex justify-content-center">
          <div>
            <div className="d-flex justify-content-center mb-3">
              <HiLockClosed size={50} color={Colors.primary} />
            </div>
            <div className="mb-4">
              <p className="heading text-center">{props.heading}</p>
              <p className="closedContent-content text-center">
                {props.content}
              </p>
            </div>
            {props.buttonText && (
              <div className="closedContent-button-wrapper">
                <Button
                  name={props.buttonText}
                  type={"primary"}
                  onClick={props.onClick}
                />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
