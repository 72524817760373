import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api} from "../api/api";

export const fetchFood = createAsyncThunk(
  'food/all',
  async function () {
    const response = await api.get('/food/all3')
    return response.data.foods
  }
)

export const fetchFoodByCategory = createAsyncThunk(
  'food/ByCategory',
  async function (category) {
    console.log('get')
    const response = await api.post('/food/ByCategory', {category})
    return {
      list: response.data.foods,
      category
    }
  }
)

export const fetchFoodCategory = createAsyncThunk(
  'food/category',
  async function () {
    const response = await api.get('/food/category')
    return response.data.category
  }
)

export const fetchCustomFood = createAsyncThunk(
  'food/custom',
  async function () {
    const response = await api.get('/food/my_all')
    return response.data.foods
  }
)

export const deleteCustomFood = createAsyncThunk(
  "dish/delete_food",
  async function (id) {
    const response = await api.post("/dish/RemovePersonal", {
      id: id,
      type: "food",
    });
    return response.data;
  }
);

const checkItem = (arr, item) => {
  const ids = [];
  arr.map(el => {
    ids.push(el.id)
  })

  const index = ids.indexOf(item.id);

  if(index > -1) {
    arr.splice(index, 1)
  } else {
    arr.push(item)
  }
}

const initialState = {
  list: [],
  category: [],
  loading: [],
  item: {},
  activeCategoryFood: '',
  customFood: [],
  myCustomFood: [],
};

export const food = createSlice({
  name: "food",
  initialState,
  reducers: {
    setDishItem: (state, action) => {
      state.item = action.payload
    },
    setDishLoading: (state, action) => {
      state.loading = action.payload
    },
    setFoodCategory: (state, action) => {
      state.activeCategoryFood = action.payload
    },
    setCustomFood: (state, action) => {
      checkItem(state.customFood, action.payload);
    },
    emptyCustomFood: (state, action) => {
      state.customFood = [];
    }
  },
  extraReducers: {
    [fetchFood.fulfilled]: (state, action) => {
      state.list = action.payload
      state.loading = false
    },
    [fetchFoodByCategory.fulfilled]: (state, action) => {
      state.list.push({
        type: action.payload.category,
        list: action.payload.list
      })
      state.loading = false
    },
    [fetchFoodCategory.fulfilled]: (state, action) => {
      state.category = action.payload
      state.activeCategoryFood = state.activeCategoryFood || action.payload[0]
      state.loading = false
    },
    [fetchCustomFood.fulfilled]: (state, action) => {
      state.myCustomFood = action.payload
    }
  },
});


export const {setFoodLoading, setFoodCategory, setCustomFood, emptyCustomFood} = food.actions;
export default food.reducer;
