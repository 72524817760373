import React from "react";
import { motion } from "framer-motion";
import { MdOutlineDoneOutline} from 'react-icons/md'

export default function DonePopup(props) {
  return (
    <div className="donePopup">
      <motion.div
        className="donePopup-wrapper"
        initial={{ opacity: 0, y: -400 }}
        animate={{ opacity: props.visible ? 1 : 0, y: 0 }}
        exit={{opacity: 0, y: 500}}
      >
        <MdOutlineDoneOutline color='white' size={50} />
      </motion.div>
    </div>
  );
}
