import React from "react";
import { useLocation } from "react-router-dom";
import Card from "../components/Card";
import IngredientCard from "../components/IngredientCard";
import AlimentiDetails from "../components/AlimentiDetails";
import { setItemModalFood } from "../redux/menu";
import { useDispatch, useSelector } from "react-redux";

export default function MyFood() {
  const dispatch = useDispatch();
  const location = useLocation();
  const myCustomFood = useSelector(state => state.food.myCustomFood)

  return (
    <div>
      {location.pathname ===
        "/percorso/alimentazione/selectPlate/addCustomDish/selectIngredient/myFood" && (
        <Card>
          <p className="heading">I miei ingredienti</p>
          {myCustomFood.length > 0 &&
            myCustomFood.map((item, index) => (
              <div
                key={item.id}
                onClick={() => {
                  dispatch(setItemModalFood(item));
                }}
              >
                <AlimentiDetails
                  id={`${item.id}-${index}`}
                  img={item.image}
                  button="Aggiungi"
                  openModal={
                    <IngredientCard
                      key={item.id}
                      name={item.name || ""}
                      weight={`${item.weight}g`}
                      kcal={item.energia || 0}
                      carb={item.carboidrati || 0}
                      gras={item.grassi || 0}
                      prot={item.proteine || 0}
                      style={{ width: 500 }}
                    />
                  }
                  customIngredient
                  navigate={-2}
                />
              </div>
            ))}
        </Card>
      )}
    </div>
  );
}
