export const sumEnergy = (dish, food) => {
  const first = dish.reduce((acc, obj) => {
    return acc + +obj.T_energia
  },0)

  const second = food.reduce((acc, obj) => {
    return acc + +obj.energia
  },0)

  return first + second
}

export const sumCarbs = (dish, food) => {
  const first = dish.reduce((acc, obj) => {
    return acc + +obj.T_carboidrati
  },0)

  const second = food.reduce((acc, obj) => {
    return acc + +obj.carboidrati
  },0)

  return first + second
}

export const sumProte = (dish, food) => {
  const first = dish.reduce((acc, obj) => {
    return acc + +obj.T_proteine
  },0)

  const second = food.reduce((acc, obj) => {
    return acc + +obj.proteine
  },0)

  return first + second
}

export const sumGras = (dish, food) => {
  const first = dish.reduce((acc, obj) => {
    return acc + +obj.T_grassi
  },0)

  const second = food.reduce((acc, obj) => {
    return acc + +obj.grassi
  },0)

  return first + second
}

export const sumFibre = (dish, food) => {
  const first = dish.reduce((acc, obj) => {
    return acc + +obj.T_figre
  },0)

  const second = food.reduce((acc, obj) => {
    return acc + +obj.figre
  },0)

  return first + second
}

export const sumZuccheri = (dish, food) => {
  const first = dish.reduce((acc, obj) => {
    return acc + +obj.T_zuccheri
  },0)

  const second = food.reduce((acc, obj) => {
    return acc + +obj.zuccheri
  },0)

  return first + second
}

export const sumWeight = (dish, food) => {
  const first = dish.reduce((acc, obj) => {
    return acc + +obj.T_weight
  },0)

  const second = food.reduce((acc, obj) => {
    return acc + +obj.weight
  },0)

  return first + second
}