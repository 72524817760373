import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from "react-slider";
import { HiX, HiOutlineHeart, HiHeart } from "react-icons/hi";
import Label from "./Label";
import Colors from "../constants/Colors";
import Card from "./Card";
import Button from "./Button";
import { setMenuFood } from "../redux/menu";
import { toggleFavoriteFood } from "../redux/favorites";
import ImageRender from "./ImageRender";
import { calcMacros } from "../helper/calc/calcMacros";
import { updateQuantity } from "../redux/menu";
import DonePopup from "./DonePopup";
import { AnimatePresence } from "framer-motion";
import { setCustomFood } from "../redux/food";
import { useNavigate } from "react-router-dom";

export default function AlimentiDetails(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemModalFood = useSelector((state) => state.menu.itemModalFood);
  const favoriteFood = useSelector(
    (state) => state.favorites.favorites.favoriteFood
  );
  const [quantity, setQuantity] = useState(100);
  const [showDonePopup, setShowDonePopup] = useState(false);

  const idFood = [];

  const modalRef = useRef(null);

  const handleClose = () => {
    modalRef.current.setAttribute("data-bs-dismiss", "modal");
    modalRef.current.click();
  };

  if (favoriteFood && favoriteFood.length > 0) {
    favoriteFood.map((el) => {
      idFood.push(el.id);
    });
  }

  const popupTimeout = () => {
    setShowDonePopup(true);
    setTimeout(() => {
      setShowDonePopup(false);
    }, 1500);
  };

  return (
    <div className="dishDetails">
      <AnimatePresence>
        {showDonePopup && <DonePopup visible={showDonePopup} />}
      </AnimatePresence>
      <a
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#dishDetailsModal-${props.id}`}
        className="dishDetails-openButton"
      >
        {props.openModal}
      </a>

      <div
        className="modal fade overlay-dark"
        id={`dishDetailsModal-${props.id}`}
        tabIndex="-1"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-scrollable dishDetails-modal-dialog">
          <div className="modal-content">
            <div className="modal-header dishDetails-modal-header justify-content-center">
              <button
                type="button"
                className="dishDetails-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="dishDetails-title">
                Aggiungi l’alimento nel tuo menu
              </p>
            </div>
            <div className="modal-body">
              <div className="container mt-4">
                <div className="d-flex align-items-center mb-4">
                  <p className="heading mb-0">{itemModalFood.name}</p>
                  <Label
                    labelText={`${Math.floor(
                      calcMacros(itemModalFood.energia, quantity) || 0
                    )} Kcal`}
                    labelColor={Colors.green}
                    labelWidth={80}
                    style={{ marginLeft: 10 }}
                  />
                </div>
                <div className="row">
                  <div className="col-xl-7 col-lg-7 col-md-12 mb-md-3">
                    <div className="dishDetails-image-wrapper">
                      <ImageRender
                        idImg={props.img}
                        initialName={itemModalFood.name}
                        bigText
                      />
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-12">
                    <div className="dishDetails-items-wrapper d-flex justify-content-center">
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">carboidrati</p>
                        <p className="dishDetails-item-quantity">
                          {props.menu
                            ? itemModalFood.carboidrati
                            : calcMacros(itemModalFood.carboidrati, quantity)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">grassi</p>
                        <p className="dishDetails-item-quantity">
                          {props.menu
                            ? itemModalFood.grassi
                            : calcMacros(itemModalFood.grassi, quantity)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">proteine</p>
                        <p className="dishDetails-item-quantity">
                          {props.menu
                            ? itemModalFood.proteine
                            : calcMacros(itemModalFood.proteine, quantity)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">zuccheri</p>
                        <p className="dishDetails-item-quantity">
                          {props.menu
                            ? itemModalFood.zuccheri
                            : calcMacros(itemModalFood.zuccheri, quantity)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">fibre</p>
                        <p className="dishDetails-item-quantity">
                          {props.menu
                            ? itemModalFood.figre
                            : calcMacros(itemModalFood.figre, quantity)}
                        </p>
                      </div>
                      <div className="dishDetails-item text-center">
                        <p className="dishDetails-item-text">peso</p>
                        <p className="dishDetails-item-quantity">
                          {props.menu
                            ? itemModalFood.weight
                            : calcMacros(itemModalFood.weight, quantity)}
                        </p>
                      </div>
                    </div>
                    <Card>
                      <p className="heading heading-card">
                        {props.menu
                          ? "Quantità selezionata"
                          : "Seleziona la quantità"}
                      </p>
                      <div className="alimenti-quantity-container d-flex justify-content-center align-items-center align-self-center">
                        {props.menu ? (
                          <p className="alimenti-quantity-text">
                            {itemModalFood.weight}
                          </p>
                        ) : (
                          <input
                            value={quantity}
                            className="alimenti-quantity-text alimenti-quantity-input"
                            onChange={(e) => {
                              setQuantity(+e.target.value);
                              dispatch(updateQuantity(+e.target.value));
                            }}
                            disabled={props.menu}
                          />
                        )}
                      </div>
                      {!props.menu && (
                        <div className="mt-4 mb-5">
                          <ReactSlider
                            className="alimenti-slider"
                            thumbClassName="alimenti-slider_thumb"
                            trackClassName="alimenti-slider_track"
                            min={0}
                            max={1000}
                            step={5}
                            value={quantity}
                            onChange={(value) => {
                              setQuantity(value);
                              dispatch(updateQuantity(value));
                            }}
                          />
                        </div>
                      )}
                    </Card>
                    <div className="mt-4">
                      <Button
                        name={
                          idFood?.includes(itemModalFood.id)
                            ? "Piatto Preferito"
                            : "Aggiungi ai Preferiti"
                        }
                        type={"outline"}
                        icon={
                          idFood?.includes(itemModalFood.id) ? (
                            <HiHeart size={25} />
                          ) : (
                            <HiOutlineHeart size={25} />
                          )
                        }
                        onClick={() =>
                          dispatch(toggleFavoriteFood(itemModalFood))
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <Button
                        name={props.button || "Aggiungi al Menu"}
                        type={"primary"}
                        onClick={() => {
                          if (props.disabled) {
                            return null;
                          } else if (props.customIngredient) {
                            dispatch(
                              setCustomFood({
                                id: itemModalFood.id,
                                name: itemModalFood.name,
                                category: itemModalFood.category,
                                energia: parseInt(
                                  calcMacros(itemModalFood.energia, quantity)
                                ),
                                carboidrati: parseInt(
                                  calcMacros(
                                    itemModalFood.carboidrati,
                                    quantity
                                  )
                                ),
                                grassi: parseInt(
                                  calcMacros(itemModalFood.grassi, quantity)
                                ),
                                proteine: parseInt(
                                  calcMacros(itemModalFood.proteine, quantity)
                                ),
                                zuccheri: parseInt(
                                  calcMacros(itemModalFood.zuccheri, quantity)
                                ),
                                figre: parseInt(
                                  calcMacros(itemModalFood.figre, quantity)
                                ),
                                weight: parseInt(
                                  calcMacros(itemModalFood.weight, quantity)
                                ),
                                originalData: {
                                  id: itemModalFood.id,
                                  name: itemModalFood.name,
                                  category: itemModalFood.category,
                                  energia: itemModalFood.energia,
                                  carboidrati: itemModalFood.carboidrati,
                                  grassi: itemModalFood.grassi,
                                  proteine: itemModalFood.proteine,
                                  zuccheri: itemModalFood.zuccheri,
                                  figre: itemModalFood.figre,
                                  weight: itemModalFood.weight,
                                },
                              })
                            );
                            popupTimeout();
                            handleClose();
                            navigate(props.navigate);
                          } else {
                            dispatch(
                              setMenuFood({
                                id: itemModalFood.id,
                                name: itemModalFood.name,
                                category: itemModalFood.category,
                                energia: parseInt(
                                  calcMacros(itemModalFood.energia, quantity)
                                ),
                                carboidrati: parseInt(
                                  calcMacros(
                                    itemModalFood.carboidrati,
                                    quantity
                                  )
                                ),
                                grassi: parseInt(
                                  calcMacros(itemModalFood.grassi, quantity)
                                ),
                                proteine: parseInt(
                                  calcMacros(itemModalFood.proteine, quantity)
                                ),
                                zuccheri: parseInt(
                                  calcMacros(itemModalFood.zuccheri, quantity)
                                ),
                                figre: parseInt(
                                  calcMacros(itemModalFood.figre, quantity)
                                ),
                                weight: parseInt(
                                  calcMacros(itemModalFood.weight, quantity)
                                ),
                                originalData: {
                                  id: itemModalFood.id,
                                  name: itemModalFood.name,
                                  category: itemModalFood.category,
                                  energia: itemModalFood.energia,
                                  carboidrati: itemModalFood.carboidrati,
                                  grassi: itemModalFood.grassi,
                                  proteine: itemModalFood.proteine,
                                  zuccheri: itemModalFood.zuccheri,
                                  figre: itemModalFood.figre,
                                  weight: itemModalFood.weight,
                                },
                              })
                            );
                            popupTimeout();
                            handleClose();
                            navigate(props.navigate);
                          }
                        }}
                        style={
                          props.menu && {
                            backgroundColor: Colors.primaryLightShadow,
                            opacity: 1,
                          }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
