import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  token: null,
  typeAuth: null,
  role: null,
};

export const authenticateUser = createSlice({
  name: "authenticate",
  initialState,
  reducers: {
    login: (state, action) => {
      setToken(action.payload.token)
      state.typeAuth = true;
      state.role = action.payload.role;
      state.token = action.payload.token;
    },
    logout: (state, action) => {
      console.log('we remove token')
      removeItemValue('token')
      state.token = null;
      state.role = null;
      state.typeAuth = false;
    },
    setTypeAuth: (state, action) => {
      state.typeAuth = action.payload.typeAuth;
    },
  },
});

export async function removeItemValue(key) {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (exception) {
    return false;
  }
}

export const getToken = async () => {
  try {
    const value =  localStorage.getItem('token')
    if (value !== null) {
      return value
    }
  } catch (e) {
    // read error
  }
}
export const setToken = async (token) => {
  try {
   localStorage.setItem('token', token)
  } catch (e) {
    // save error
  }
}

export const {login, logout, setTypeAuth} = authenticateUser.actions;
export default authenticateUser.reducer;
