import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const fetchTutorial = createAsyncThunk("tutorial", async function () {
  const response = await api.get("/tutorial/all");
  return response.data.list;
});

const checkItem = (arr, itemId) => {
  const ids = [];
  arr.map((el) => {
    ids.push(el.id);
  });

  const index = ids.indexOf(itemId);

  arr.splice(index, 1);
};

const initialState = {
  list: [],
  tutorialLoading: true,
  item: {},
  howToList: [],
  whatsNewList: [],
  tutorialCategory: null,
  introVincente: {},
  introNormale: {},
  checkTutorial: {},
};

export const tutorial = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    setTutorialItem: (state, action) => {
      state.item = action.payload;
    },
    setTutorialLoading: (state, action) => {
      state.tutorialLoading = action.payload;
    },
    setTutorialCategory: (state, action) => {
      state.tutorialCategory = action.payload;
    },
    setTutorialClear: (state, action) => {
      state.howToList = [];
      state.whatsNewList = [];
    },
  },
  extraReducers: {
    [fetchTutorial.fulfilled]: (state, action) => {
      state.list = action.payload;
      action.payload.map((el) => {
        if (el.category === "How To") {
          state.howToList.push(el);
        } else if (el.category === "Whats New") {
          state.whatsNewList.push(el);
        }

        if(el.id === 34) {
          state.introVincente = el
        }

        if(el.id === 35) {
          state.introNormale = el
        }

        if(el.id === 48) {
          state.checkTutorial = el
        }

      });

      checkItem(state.howToList, 34);
      checkItem(state.howToList, 35);
      checkItem(state.howToList, 48);

      state.tutorialLoading = false;
    },
  },
});

export const {
  setTutorialItem,
  setTutorialLoading,
  setTutorialCategory,
  setTutorialClear,
} = tutorial.actions;
export default tutorial.reducer;
