import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Card from "../components/Card";
import { InputCheck, TextArea } from "../components/Input";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Button from "../components/Button";
import ImagePicker from "../components/ImagePicker";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../constants/Colors";
import { emptyCustomFood } from "../redux/food";
import { fetchCustomDish } from "../redux/dish";
import { api, apiUrl } from "../api/api";
import Popup from "../components/Popup";
import AlimentiDetails from "../components/AlimentiDetails";
import IngredientCard from "../components/IngredientCard";
import { setItemModalFood } from "../redux/menu";
import { fixOne } from "../helper/calc/fixOne";

export default function AddCustomDish() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const customFoodList = useSelector((state) => state.food.customFood);
  const [categoryValue, setCategoryValue] = useState(null);
  const [categories, setCategories] = useState([
    { label: "Antipasti", value: "Antipasti" },
    { label: "Primi Piatti", value: "Primi Piatti" },
    { label: "Secondi Piatti", value: "Secondi Piatti" },
    { label: "Contorni", value: "Contorni" },
    { label: "Frutta", value: "Frutta" },
    { label: "Condimenti", value: "Condimenti" },
    { label: "Dessert", value: "Dessert" },
  ]);
  const [image, setImage] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [dishName, setDishName] = useState("");
  const [description, setDescription] = useState("");
  const [instruction, setInstruction] = useState("");
  const [proteine, setProteine] = useState();
  const [grassi, setGrassi] = useState();
  const [carboidrati, setCarboidrati] = useState();
  const [energia, setEnergia] = useState();
  const [weight, setWeight] = useState();
  const [zuccheri, setZuccheri] = useState();
  const [fibre, setFibre] = useState();
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  useEffect(() => {
    if (customFoodList.length > 0) {
      const prot = [];
      const carb = [];
      const gras = [];
      const kcal = [];
      const weight = [];
      const fibre = [];
      const zuccheri = [];
      customFoodList.map((el) => {
        prot.push(fixOne(el.proteine));
        carb.push(fixOne(el.carboidrati));
        gras.push(fixOne(el.grassi));
        kcal.push(fixOne(el.energia));
        weight.push(fixOne(el.weight));
        fibre.push(fixOne(el.figre));
        zuccheri.push(fixOne(el.zuccheri));
      });
      setProteine(prot.reduce((a, b) => a + b, 0));
      setGrassi(gras.reduce((a, b) => a + b, 0));
      setCarboidrati(carb.reduce((a, b) => a + b, 0));
      setEnergia(kcal.reduce((a, b) => a + b, 0));
      setWeight(weight.reduce((a, b) => a + b, 0));
      setFibre(fibre.reduce((a, b) => a + b, 0));
      setZuccheri(zuccheri.reduce((a, b) => a + b, 0));
    }
  }, [customFoodList]);

  const checkCustomFood = () => {
    if (customFoodList.length <= 0) {
      setShowPopup({
        visible: true,
        heading: "ATTENZIONE",
        message: "Per continuare devi aggiungere almeno un ingrediente!",
        button: "Riprova",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => {
          setShowPopup({ visible: false });
        },
      });
    } else {
      checkFields();
    }
  };

  const checkFields = () => {
    if (
      !!dishName &&
      !!description &&
      !!instruction &&
      !!image &&
      !!categoryValue
    ) {
      submitDish();
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENZIONE",
        message: "Tutti i campi sono obbligatori!",
        button: "Riprova",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => {
          setShowPopup({ visible: false });
        },
      });
    }
  };

  const submitDish = () => {
    api
      .post("/dish/my_insert", {
        name: dishName,
        category: categoryValue.value,
        description: description,
        instruction: instruction,
        onnivori: "1",
        vegetarianii: "1",
        vegani: "1",
        pescetariani: "1",
        ceriali: "0",
        crostacei: "0",
        arachidi: "0",
        soia: "0",
        frutta: "0",
        senape: "0",
        sesamo: "0",
        solfiti: "0",
        sedano: "0",
        uova: "0",
        moluschi: "0",
        lupini: "0",
        latte: "0",
        collazione: "1",
        pranzo: "1",
        cena: "1",
        spuntini: "1",
        priorita: "pr1",
        image: image,
        gadients: JSON.stringify(customFoodList),
        T_energia: energia,
        T_carboidrati: carboidrati,
        T_grassi: grassi,
        T_proteine: proteine,
        T_zuccheri: zuccheri,
        T_figre: fibre,
        T_weight: weight,
      })
      .then((response) => {
        console.log(response, "dish submitted");
        dispatch(fetchCustomDish());
        setShowPopup({
          visible: true,
          heading: "FATTO",
          message: "Il tuo piatto e stato aggiunto con successo!",
          button: "OK",
          onClose: () => {
            navigate("/percorso/alimentazione/selectPlate");
            setShowPopup({ visible: false });
          },
          onClick: () => {
            dispatch(emptyCustomFood());
            navigate("/percorso/alimentazione/selectPlate");
            setShowPopup({ visible: false });
          },
        });
      })
      .catch((err) => console.log(err));
  };

  const uploadImage = async (checkImg) => {
    setImageLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("img[]", checkImg, checkImg.name);
    formdata.append("type", "dish");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/img/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setImage(data.id.response[0]);
        setImageLoading(false);
      })
      .catch((error) => {
        console.log("error uploading image", error);
        setImageLoading(false);
      });
  };

  return (
    <div>
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      {location.pathname ===
        "/percorso/alimentazione/selectPlate/addCustomDish" && (
        <Card>
          <div className="customDish-wrapper">
            <Dropdown
              options={categories}
              onChange={setCategoryValue}
              value={categoryValue}
              placeholder="Seleziona la categoria"
            />
            <InputCheck
              description="Nome"
              onChange={(e) => setDishName(e.target.value)}
            />
            <TextArea
              description="Descrizione"
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextArea
              description="Instruzioni"
              onChange={(e) => setInstruction(e.target.value)}
            />
            {customFoodList.length > 0 &&
              customFoodList.map((item, index) => (
                <div
                  key={item.id}
                  onClick={() => {
                    dispatch(setItemModalFood(item));
                  }}
                >
                  <AlimentiDetails
                    id={`${item.id}-${index}`}
                    img={item.image}
                    button="Rimuovi"
                    openModal={
                      <IngredientCard
                        key={item.id}
                        name={item.name || ""}
                        weight={`${item.weight}g`}
                        kcal={item.energia || 0}
                        carb={item.carboidrati || 0}
                        gras={item.grassi || 0}
                        prot={item.proteine || 0}
                        style={{ width: "100%", marginTop: -7 }}
                      />
                    }
                    customIngredient
                  />
                </div>
              ))}
            <Button
              type="outline"
              name="+ Seleziona Alimenti"
              style={{ marginTop: 8 }}
              onClick={() => navigate("selectIngredient")}
            />
            <Card>
              <div className="check-image-wrapper align-self-center">
                <ImagePicker
                  onImageTaken={(imagePath) =>
                    imagePath && uploadImage(imagePath)
                  }
                />
              </div>
            </Card>
            <Button
              disabled={imageLoading}
              type="primary"
              name={imageLoading ? "Loading Images" : "Fatto"}
              style={{
                marginTop: 8,
                backgroundColor: imageLoading
                  ? Colors.primaryLightShadow
                  : Colors.primary,
              }}
              onClick={checkCustomFood}
            />
          </div>
        </Card>
      )}
      <Outlet />
    </div>
  );
}
