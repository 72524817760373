import React from 'react'

export default function InformationCard(props) {
  return (
    <div className='informationCard' onClick={props.onClick}>
      <p className="informationCard-placeholder">{props.placeholder}</p>
      <p className="informationCard-info">{props.info}</p>
    </div>
  )
}
