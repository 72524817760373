import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../components/Card";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { api } from "../api/api";
import { useDispatch } from "react-redux";
import { fetchCustomFood } from "../redux/food";
import { InputCheck } from "../components/Input";
import Button from "../components/Button";
import Popup from "../components/Popup";

export default function AddCustomIngredient(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [categoryValue, setCategoryValue] = useState(null);
  const [categories, setCategories] = useState([
    { label: "Ceriali e Derivanti", value: "Cer/Deriv" },
    { label: "Legumi", value: "Leg" },
    { label: "Verdure", value: "Verd" },
    { label: "Frutta", value: "Frutta" },
    { label: "Carni", value: "Carne" },
    { label: "Carni Transformate", value: "Carni Tran" },
    { label: "Frattaglie", value: "Frattaglie" },
    { label: "Pesce", value: "Pesce" },
    { label: "Latte e Yoghurt", value: "Latte e Yogurt" },
    { label: "Formaggi", value: "Formaggi" },
    { label: "Uova", value: "Upva" },
    { label: "Grassi e Condimenti", value: "Grassi/Cond" },
    { label: "Dolci", value: "Dolci" },
    { label: "Prodotti Vari", value: "Prodotti Vari" },
    { label: "Alcool", value: "Alcool" },
    { label: "Bevande e Bibite", value: "Bevande e Bibite" },
  ]);
  const [ingredientName, setIngredientName] = useState("");
  const [carboidrati, setCarboidrati] = useState("");
  const [grassi, setGrassi] = useState("");
  const [proteine, setProteine] = useState("");
  const [kcal, setKcal] = useState("");
  const [zuccheri, setZuccheri] = useState("");
  const [fibre, setFibre] = useState("");
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const checkFields = () => {
    if (
      !!ingredientName &&
      !!carboidrati &&
      !!grassi &&
      !!proteine &&
      !!kcal &&
      !!zuccheri &&
      !!fibre &&
      !!categoryValue
    ) {
      submitIngredient();
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENZIONE",
        message: "Tutti i campi sono obbligatori!",
        button: "Riprova",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => {
          setShowPopup({ visible: false });
        },
      });
    }
  };

  const submitIngredient = () => {
    api
      .post("/food/my_insert", {
        category: categoryValue.value,
        name: ingredientName,
        energia: kcal,
        carboidrati: carboidrati,
        grassi: grassi,
        proteine: proteine,
        zuccheri: zuccheri,
        figre: fibre,
        weight: 100,
      })
      .then((response) => {
        console.log(response.data, "res from add custom ingredient");
        dispatch(fetchCustomFood());
        setShowPopup({
          visible: true,
          heading: "FATTO",
          message: "Il tuo ingrediente e stato aggiunto con successo!",
          button: "OK",
          onClose: () => {
            navigate("/percorso/alimentazione/selectPlate");
            setShowPopup({ visible: false });
          },
          onClick: () => {
            navigate("/percorso/alimentazione/selectPlate");
            setShowPopup({ visible: false });
          },
        });
      })
      .catch((err) => console.log(err));
  };

  const replaceSymbols = (value) => {
    const newValue = value.replace(",", ".");
    const dots = newValue.split(".").length - 1;
    if (newValue.includes(",") || dots > 1 || newValue === ".") {
      return "";
    } else {
      return newValue;
    }
  };

  return (
    <div>
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      {location.pathname ===
        "/percorso/alimentazione/selectPlate/addCustomIngredient" && (
        <Card>
          <div className="customIngredient-wrapper">
            <Dropdown
              options={categories}
              onChange={setCategoryValue}
              value={categoryValue}
              placeholder="Seleziona la categoria"
            />
            <InputCheck
              description="Nome"
              onChange={(e) => setIngredientName(e.target.value)}
            />
            <p className="heading" style={{ marginTop: 15 }}>
              Inserisci i valori nutrizionali per 100g di prodotto
            </p>
            <InputCheck
              description="Carboidrati"
              onChange={(e) => setCarboidrati(replaceSymbols(e.target.value))}
              number
            />
            <InputCheck
              description="Grassi"
              onChange={(e) => setGrassi(replaceSymbols(e.target.value))}
              number
            />
            <InputCheck
              description="Proteine"
              onChange={(e) => setProteine(replaceSymbols(e.target.value))}
              number
            />
            <InputCheck
              description="Zuccheri"
              onChange={(e) => setZuccheri(replaceSymbols(e.target.value))}
              number
            />
            <InputCheck
              description="Fibre"
              onChange={(e) => setFibre(replaceSymbols(e.target.value))}
              number
            />
            <InputCheck
              description="KCAL"
              onChange={(e) => setKcal(replaceSymbols(e.target.value))}
              number
            />
            <Button
              type="primary"
              name="Fatto"
              onClick={checkFields}
              style={{ marginTop: 8 }}
            />
          </div>
        </Card>
      )}
    </div>
  );
}
