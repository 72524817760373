import React from "react";

export default function Label(props) {
  return (
    <div
      className="label"
      style={{
        backgroundColor: props.labelColor,
        width: props.labelWidth,
        margin: props.labelMargin,
        ...props.style
      }}
    >
      <p className="label-text">{props.labelText}</p>
    </div>
  );
}
