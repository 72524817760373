import React from "react";
import { HiX } from "react-icons/hi";
import { useSelector } from "react-redux";
import Card from "./Card";
import Label from "./Label";
import VideoPlayer from "./VideoPlayer";
import { checkCategory } from "../helper/categoryName";
import Colors from "../constants/Colors";

export default function IntroAllenamentiModal(props) {
  const sessioneCategory = useSelector(
    (state) => state.profile.sessioneCategory
  );

  return (
    <div className="introAllenamenti">
      <a
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#introAllenamentiModal"
      >
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="introAllenamentiModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable introAllenamenti-modal-dialog">
          <div className="modal-content">
            <div className="modal-header introAllenamenti-modal-header justify-content-center">
              <button
                type="button"
                className="introAllenamenti-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="introAllenamenti-title">Video Introduzione</p>
            </div>
            <div className="modal-body">
              <div className="introAllenamenti-body-wrapper">
                <div className="d-flex align-items-center">
                  <p className="heading mb-0 me-3">
                    {sessioneCategory?.videoSelected_info.name}
                  </p>
                  <Label
                    labelText={checkCategory(sessioneCategory?.category)}
                    labelColor={Colors.green}
                  />
                </div>
                <Card style={{ padding: 0 }}>
                  <VideoPlayer
                    link={sessioneCategory?.videoSelected_info.link}
                  />
                </Card>
                <Card>
                  <p className="heading heading-card">Descrizione</p>
                  <p className="contentText">{sessioneCategory?.description}</p>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
