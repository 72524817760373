import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { api } from "../api/api";
import Card from "../components/Card";
import Loader from "../components/Loader";
import { alimentiCategory } from "../helper/categoryName";
import AlimentiDetails from "../components/AlimentiDetails";
import IngredientCard from "../components/IngredientCard";
import Button from "../components/Button";
import { fetchFoodCategory, fetchFood, setFoodCategory } from "../redux/food";
import { HiSearch } from "react-icons/hi";
import Colors from "../constants/Colors";
import { setItemModalFood } from "../redux/menu";

export default function SelectIngredient() {
  const location = useLocation();
  return (
    <div>
      {location.pathname ===
        "/percorso/alimentazione/selectPlate/addCustomDish/selectIngredient" && (
        <Alimenti />
      )}
      <Outlet />
    </div>
  );
}

const FoodTypeAlimenti = ({ item, name }) => {
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.food.activeCategoryFood);

  const select = () => {
    dispatch(setFoodCategory(item));
  };

  return (
    <div onClick={() => select()}>
      <div
        className={
          activeCategory === item ? "filter-itemActive " : "filter-item"
        }
      >
        <p
          className={
            activeCategory === item
              ? "filter-itemTextActive "
              : "filter-itemText"
          }
        >
          {name}
        </p>
      </div>
    </div>
  );
};

const AlimentiCard = ({ search }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [slice, setSlice] = useState(15);
  const activeCategory = useSelector((state) => state.food.activeCategoryFood);
  const [loading, setLoading] = useState(false);
  const quantity = useSelector((state) => state.menu.quantity);
  const [iconShow, setIconShow] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);

    api
      .post("/food/ByCategory", { category: activeCategory })
      .then((res) => {
        if (!unmounted) {
          setList(res.data.foods);
        }
      })
      .finally(() => {
        if (!unmounted) setLoading(false);
      });

    return () => (unmounted = true);
  }, [activeCategory]);

  const getList = () => {
    if (search.length > 2) {
      return list.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      return list;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={loading} size={50} />
      </div>
    );
  }

  if (search.length > 2 && getList().length === 0) {
    return null;
  }

  return (
    <Card>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="heading heading-card">
          {alimentiCategory(activeCategory)} ({getList()?.length || 0})
        </p>
      </div>
      <div className="d=flex justify-content-center">
        {getList()?.length > 0 &&
          getList()
            .slice(0, slice)
            .map((item, index) => (
              <div
                key={item.id}
                onClick={() => {
                  dispatch(setItemModalFood(item));
                }}
              >
                <AlimentiDetails
                  id={`${item.id}-${index}`}
                  img={item.image}
                  button="Aggiungi"
                  openModal={
                    <IngredientCard
                      key={item.id}
                      name={item.name || ""}
                      weight={`${item.weight}g`}
                      kcal={item.energia || 0}
                      carb={item.carboidrati || 0}
                      gras={item.grassi || 0}
                      prot={item.proteine || 0}
                      style={{ width: 500 }}
                    />
                  }
                  customIngredient
                  navigate={-1}
                />
              </div>
            ))}
      </div>
      {slice > 0 && getList()?.length > slice ? (
        <div
          onClick={() => {
            setSlice(slice + 15);
          }}
          className="mt-3"
        >
          <Button name="More" type="outline" />
        </div>
      ) : null}
    </Card>
  );
};

const Alimenti = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.food.category);
  const foodLoading = useSelector((state) => state.food.loading);
  const [search, setSearch] = useState("");
  const shownCategory = category.slice(0, -1);

  useEffect(() => {
    dispatch(fetchFoodCategory());
    dispatch(fetchFood());
  }, []);

  return (
    <div>
      <div className="d-flex mt-3">
        <Button
          type="outline"
          name="+ Aggiungi Alimenti"
          onClick={() => navigate("/percorso/alimentazione/selectPlate/addCustomIngredient")}
          style={{ marginRight: 10 }}
        />
        <Button
          type="primary"
          name="I miei Alimenti"
          onClick={() => navigate("myFood")}
          style={{ marginLeft: 10 }}
        />
      </div>
      <div className="d-flex flex-wrap mt-4">
        {shownCategory?.length > 0 &&
          shownCategory.map((el) => (
            <FoodTypeAlimenti item={el} key={el} name={alimentiCategory(el)} />
          ))}
      </div>
      <div className="alimenti_search-container mt-3 d-flex align-items-center">
        <HiSearch
          size={20}
          color={Colors.primaryLight}
          style={{ marginRight: 10 }}
        />
        <input
          className="alimenti_input"
          placeholder="Cerca"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <AlimentiCard search={search} />
    </div>
  );
};
