import React, { useEffect } from "react";
import IgnoreHeader from "../components/IgnoreHeader";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteListNotification,
  deleteNewNotification,
  moveNotification,
} from "../redux/notifications";
import moment from "moment";
import { setBookItem, setBookType } from "../redux/books";
import {
  setCourseType,
  fetchVideoCourseById,
  setVideoCourseLoading,
} from "../redux/videoCourses";
import Loader from "../components/Loader";
import {
  fetchNotifications,
  fetchStorageNewNotifications,
  fetchStorageListNotifications,
} from "../redux/notifications";
import { HiX } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function Notifications() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationLoading = useSelector(
    (state) => state.notifications.notificationLoading
  );
  const listLoading = useSelector((state) => state.notifications.listLoading);
  const newLoading = useSelector((state) => state.notifications.newLoading);
  const newNotifications = useSelector(
    (state) => state.notifications.newNotifications
  );
  const listNotifications = useSelector(
    (state) => state.notifications.listNotifications
  );
  const books = useSelector((state) => state.books.list);
  const videoCourse = useSelector((state) => state.videoCourse.list);
  const reversedNew = [...newNotifications].reverse();
  const reversedList = [...listNotifications].reverse();

  useEffect(() => {
    dispatch(fetchStorageListNotifications());
    dispatch(fetchStorageNewNotifications());
    dispatch(fetchNotifications());
  }, []);

  if (notificationLoading || listLoading || newLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader
          loading={notificationLoading || listLoading || newLoading}
          size={50}
        />
      </div>
    );
  }

  if (reversedNew.length <= 0 && reversedList.length <= 0) {
    return (
      <div className="d-flex justify-content-center" style={{marginTop: 200}}>
        <p className="heading">No notifications at the moment</p>
      </div>
    );
  }

  return (
    <IgnoreHeader>
      <div className="container notifications">
        <div className="notifications-wrapper">
          {reversedNew && reversedNew.length > 0
            ? reversedNew.map((el, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (el.type === "new_product_active") {
                      if (el.productType === "Libri") {
                        books.map((item) => {
                          if (item.id == el.productId) {
                            dispatch(setBookItem(item));
                            dispatch(setBookType("myItem"));
                            navigate("../percorso/disponibili/myBookDetails");
                          }
                        });
                      } else if (el.productType === "Video Corsi") {
                        videoCourse.map((item) => {
                          if (item.id == el.productId) {
                            dispatch(setVideoCourseLoading(true));
                            dispatch(fetchVideoCourseById(el.productId));
                            dispatch(setCourseType("myItem"));
                            navigate("../percorso/disponibili/myCourseDetails");
                          }
                        });
                      } else {
                        navigate("../percorso/alimentazione");
                      }
                    } else if (el.type === "menu") {
                      navigate("../percorso/alimentazione");
                    } else if (el.type === "allenamento") {
                      navigate("../percorso/allenamenti");
                    } else if (el.type === "promo_product") {
                      if (el.productType === "Libri") {
                        books.map((item) => {
                          if (item.id == el.productId) {
                            dispatch(setBookItem(item));
                            dispatch(setBookType("shopItem"));
                            navigate("../extra/bookDetails");
                          }
                        });
                      } else if (el.productType === "Video Corsi") {
                        videoCourse.map((item) => {
                          if (item.id == el.productId) {
                            dispatch(setVideoCourseLoading(true));
                            dispatch(fetchVideoCourseById(el.productId));
                            dispatch(setCourseType("shopItem"));
                            navigate("../extra/courseDetails");
                          }
                        });
                      } else {
                        navigate("../percorso/alimentazione");
                      }
                    } else {
                      navigate("../percorso/alimentazione");
                    }
                    dispatch(moveNotification(el));
                    dispatch(deleteNewNotification(el));
                  }}
                >
                  <NotificationCardActive
                    text={el.text}
                    id={el.id}
                    time={el.updated_at}
                  />
                </div>
              ))
            : null}
          {reversedList && reversedList.length > 0
            ? reversedList.map((el, index) => (
                <NotificationCard
                  text={el.text}
                  id={el.id}
                  key={index}
                  onDelete={() => dispatch(deleteListNotification(el))}
                />
              ))
            : null}
        </div>
      </div>
    </IgnoreHeader>
  );
}

const NotificationCard = (item) => {
  return (
    <Card>
      <div className="d-flex justify-content-between align-items-center">
        <p className="notificationCard-heading">Fespa</p>
        <HiX onClick={item.onDelete} size={25} style={{ cursor: "pointer" }} />
      </div>
      <p className="notificationCard-text">{item.text}</p>
    </Card>
  );
};

const NotificationCardActive = (item) => {
  const timeAgo = (time) => moment(time).fromNow();

  return (
    <Card style={{ cursor: "pointer" }}>
      <div className="d-flex justify-content-between align-items-center">
        <p className="notificationCard-heading notificationCard-heading-active">
          Fespa
        </p>
        <p className="notificationCard-time">{timeAgo(item.time)}</p>
      </div>
      <p className="notificationCard-text notificationCard-text-active">
        {item.text}
      </p>
    </Card>
  );
};
