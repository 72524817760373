export default {
  primary: '#A05BC2',
  primaryLight: '#BF84DC',
  primaryLightShadow: '#efe1f6',
  primaryText: '#9350B5',
  orange: '#F08E49',
  orangeLight: '#EBA14A',
  green: '#17BA89',
  greenLight: '#68D8B6', //#67DAB8
  greenLightShadow: '#d4f4ea',
  red: '#F55F5F',
  redLight: '#f88f8f',
  redLightShadow: '#fccfcf',
  gray: '#B5B5B5',
  grayLight: '#C8C8C8',
  grayDark: '#4A4A4A',
  black: '#2F2F2F',
  white: '#fff'
}