import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const fetchFavorites = createAsyncThunk(
  "profile/my_dish",
  async function () {
    const response = await api.get("/profile/my_dish");
    if (
      !response.data.data ||
      !Object.keys(response.data.data).length === 0 ||
      response.data.data.length === 0
    ) {
      console.log("starting new favorites");
      return initialState.menu;
    }
    return response.data.data;
  }
);

const checkItem = (arr, item) => {
  const ids = [];
  arr.map(el => {
    ids.push(el.id)
  })

  const index = ids.indexOf(item.id);

  if(index > -1) {
    arr.splice(index, 1)
  } else {
    arr.push(item)
  }
}

const initialState = {
  favorites: {
    favoriteDish: [],
    favoriteFood: [],
  },
  favoritesLoading: true,
};

export const favorites = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    toggleFavoriteDish: (state, action) => {
      checkItem(state.favorites.favoriteDish, action.payload)
    },
    toggleFavoriteFood: (state, action) => {
      checkItem(state.favorites.favoriteFood, action.payload)
    }
  },
  extraReducers: {
    [fetchFavorites.fulfilled]: (state, action) => {
      state.favorites.favoriteDish = action.payload.favoriteDish;
      state.favorites.favoriteFood = action.payload.favoriteFood;
      state.favoritesLoading = false;
    },
  },
});

export const { toggleFavoriteDish, toggleFavoriteFood } = favorites.actions;
export default favorites.reducer;
