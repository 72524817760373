import React, { useState } from "react";
import Colors from "../constants/Colors";
import { useSelector } from "react-redux";
import ImageRender from "./ImageRender";
import { BiImageAdd } from "react-icons/bi";
import { FaWindowClose } from "react-icons/fa";

export default function ImagePicker(props) {
  const [pickedImage, setPickedImage] = useState();
  const userImage = useSelector((state) => state.profile.userImage);

  const selectFile = () => {
    document.getElementById("img-picker").click();
  };

  const takeImageHandler = (e) => {
    setPickedImage(e.target.files[0]);
    props.onImageTaken(e.target.files[0]);
  };

  return (
    <div className="imagePicker">
      {props.profile ? (
        <div>
          <div className="imagePicker-profile">
            {pickedImage ? (
              <img
                src={URL.createObjectURL(pickedImage)}
                className="imagePicker-preview"
              />
            ) : (
              <div style={{ width: "100%", height: "100%" }}>
                {userImage ? (
                  <ImageRender idImg={userImage} />
                ) : (
                  <BiImageAdd
                    size={50}
                    color={Colors.primaryLight}
                    className="imagePicker-addIcon"
                    onClick={selectFile}
                  />
                )}
              </div>
            )}
            <input
              type="file"
              name="Image"
              onChange={(e) => takeImageHandler(e)}
              className="imagePicker-input-profile"
              id="img-picker"
            />
          </div>
          <p className="imagePicker-changePhoto" onClick={selectFile}>
            {userImage ? "Cambia foto Profilo" : "Carica foto Profilo"}
          </p>
        </div>
      ) : (
        <div className="imagePicker">
          {pickedImage && (
            <img
              src={URL.createObjectURL(pickedImage)}
              className="imagePicker-preview"
            />
          )}

          {pickedImage && (
            <FaWindowClose
              size={20}
              color={Colors.red}
              className="imagePicker-cancel"
              onClick={() => setPickedImage(null)}
            />
          )}
          {!pickedImage && (
            <BiImageAdd
              size={50}
              color={Colors.primaryLight}
              className="imagePicker-addIcon"
            />
          )}

          <input
            type="file"
            name="Image"
            onChange={(e) => takeImageHandler(e)}
            className="imagePicker-input"
          />
        </div>
      )}
    </div>
  );
}
