import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchLegacyAll } from "../redux/legacy";
import Colors from "../constants/Colors";

export default function LegalModal(props) {
  const [firstSwitch, setFirstSwitch] = useState(true);
  const [secondSwitch, setSecondSwitch] = useState(true);
  const [thirdSwitch, setThirdSwitch] = useState(true);
  const dispatch = useDispatch();
  const legacy = useSelector((state) => state.legacy.list);

  useEffect(() => {
    dispatch(fetchLegacyAll());
  }, []);

  return (
    <div className="legalModal">
      <div className="legalModal-wrapper">
        <p className="heading">Utilizzo dati IFCF LLC</p>
        <div>
          {legacy.length > 0 &&
            legacy.map((item, index) => (
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html:
                    item.category === "Cookie Policy" ? item.content : null,
                }}
              />
            ))}
        </div>
        <div className="legalModal-card">
          <p className="heading heading-card">Uso dei cookie</p>
          <div className="collapse" id="collapse1">
            <div>
              {legacy.length > 0 &&
                legacy.map((item, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html:
                        item.category === "Uso dei Cookie"
                          ? item.content
                          : null,
                    }}
                  />
                ))}
            </div>
          </div>
          <p className="d-flex justify-content-end more-button">
            <a
              data-bs-toggle="collapse"
              href="#collapse1"
              role="button"
              aria-expanded="false"
              aria-controls="collapse1"
            >
              Scopri di piu
            </a>
          </p>
        </div>
        <div className="legalModal-card">
          <div className="d-flex justify-content-between mb-2">
            <p className="heading heading-card">Cookie tecnici</p>
            <Switch
              checked={firstSwitch}
              onChange={() => setFirstSwitch(firstSwitch)}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={Colors.primary}
              offColor={Colors.primaryLightShadow}
              width={45}
              height={25}
              handleDiameter={22}
              offHandleColor={Colors.primary}
              onHandleColor={Colors.primaryLightShadow}
            />
          </div>
          <div className="collapse" id="collapse2">
            <div>
              {legacy.length > 0 &&
                legacy.map((item, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html:
                        item.category === "Cookie Tecnici"
                          ? item.content
                          : null,
                    }}
                  />
                ))}
            </div>
          </div>
          <p className="d-flex justify-content-end more-button">
            <a
              data-bs-toggle="collapse"
              href="#collapse2"
              role="button"
              aria-expanded="false"
              aria-controls="collapse2"
            >
              Scopri di piu
            </a>
          </p>
        </div>

        <div className="legalModal-card">
          <div className="d-flex justify-content-between mb-2">
            <p className="heading heading-card">
              Cookie di profilazione pubblicitaria e retargeting
            </p>
            <Switch
              checked={secondSwitch}
              onChange={() => setSecondSwitch(!secondSwitch)}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={Colors.primary}
              offColor={Colors.primaryLightShadow}
              width={45}
              height={25}
              handleDiameter={22}
              offHandleColor={Colors.primary}
              onHandleColor={Colors.primaryLightShadow}
            />
          </div>
          <div className="collapse" id="collapse3">
            <div>
              {legacy.length > 0 &&
                legacy.map((item, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html:
                        item.category ===
                        "Cookie di profiliazione, pubblicità e retargeting"
                          ? item.content
                          : null,
                    }}
                  />
                ))}
            </div>
          </div>
          <p className="d-flex justify-content-end more-button">
            <a
              data-bs-toggle="collapse"
              href="#collapse3"
              role="button"
              aria-expanded="false"
              aria-controls="collapse3"
            >
              Scopri di piu
            </a>
          </p>
        </div>

        <div className="legalModal-card">
          <div className="d-flex justify-content-between mb-2">
            <p className="heading heading-card">
              Cookie di statistica, analytics prime e terze parti
            </p>
            <Switch
              checked={thirdSwitch}
              onChange={() => setThirdSwitch(!thirdSwitch)}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={Colors.primary}
              offColor={Colors.primaryLightShadow}
              width={45}
              height={25}
              handleDiameter={22}
              offHandleColor={Colors.primary}
              onHandleColor={Colors.primaryLightShadow}
            />
          </div>
          <div className="collapse" id="collapse4">
            <div>
              {legacy.length > 0 &&
                legacy.map((item, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html:
                        item.category ===
                        "Cookie di statistica, analytics prime e terze parti"
                          ? item.content
                          : null,
                    }}
                  />
                ))}
            </div>
          </div>
          <p className="d-flex justify-content-end more-button">
            <a
              data-bs-toggle="collapse"
              href="#collapse4"
              role="button"
              aria-expanded="false"
              aria-controls="collapse4"
            >
              Scopri di piu
            </a>
          </p>
        </div>

        <div className="d-flex justify-content-end">
          <Button
            type="primary"
            name="Accetta selezionati"
            onClick={() => {
              props.onAcceptSelected();
              localStorage.setItem(
                "CookieOptions",
                JSON.stringify({
                  tecnici: firstSwitch,
                  profiliazione: secondSwitch,
                  statistica: thirdSwitch,
                })
              );
            }}
            style={{ height: 40, width: 200 }}
          />
        </div>
      </div>
    </div>
  );
}
