import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HiOutlineUserCircle,
  HiOutlineBell,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import {
  fetchNotifications,
  fetchStorageNewNotifications,
  fetchStorageListNotifications,
} from "../redux/notifications";
import { useDispatch, useSelector } from "react-redux";

export default function Header() {
  const dispatch = useDispatch();
  const newNotifications = useSelector(
    (state) => state.notifications.newNotifications
  );

  useEffect(() => {
    dispatch(fetchStorageListNotifications());
    dispatch(fetchStorageNewNotifications());
    dispatch(fetchNotifications());
  }, []);

  return (
    <div className="container-fluid header">
      <div className="container h-100">
        <div className="justify-content-between d-flex align-items-center h-100">
          <div className="justify-content-between d-flex align-items-center">
            <p className="header-logo">fespa</p>
            <div className="d-flex align-items-center">
              <Link to="/percorso/alimentazione">
                <p className="header-link_item">il mio percorso</p>
              </Link>
              <Link to="/extra">
                <p className="header-link_item">prodotti extra</p>
              </Link>
            </div>
          </div>
          <div className="header-icons d-flex align-items-center">
            {/* <Link to="/cart">
              <HiOutlineShoppingBag className="header-icon_item" size={22} />
            </Link> */}
            <div style={{ position: "relative" }}>
              <Link to="/notifications">
                {newNotifications && newNotifications.length > 0 && (
                  <div className="header-notification">
                    <p className="header-notification-text">
                      {newNotifications.length < 9 ? newNotifications.length : '9+'}
                    </p>
                  </div>
                )}
                <HiOutlineBell className="header-icon_item" size={22} />
              </Link>
            </div>
            <Link to="/faq">
              <HiOutlineQuestionMarkCircle
                className="header-icon_item"
                size={22}
              />
            </Link>
            <Link to="/profile">
              <HiOutlineUserCircle
                className="header-icon_item ms-5"
                size={30}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
