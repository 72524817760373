import React from "react";
import Button from "./Button";
import { motion } from "framer-motion";

export default function Popup(props) {
  return (
    <div className="popup-container">
      <motion.div
        className="popup-wrapper"
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: props.visible ? 1 : 0, y: 0 }}
      >
        <div className="popup-content">
          <p className="popup-heading">{props.heading}</p>
          <p className="popup-text">{props.message}</p>
        </div>
        <div className="popup-button-wrapper">
          <Button
            type="primary"
            name="Chiudi"
            style={{ height: 45, marginRight: 2 }}
            onClick={props.onClose}
          />
          <Button
            type="outline"
            name={props.button}
            style={{ height: 45, marginLeft: 2 }}
            onClick={props.onClick}
          />
        </div>
      </motion.div>
    </div>
  );
}
