import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const fetchBooks = createAsyncThunk("books/all", async function () {
  const { data } = await api.get("/book_store/all");
  return data.list;
});

export const fetchBookPdf = createAsyncThunk(
  "books/pdf",
  async function (id, token) {
    const response = await api.get(
      `/img/download_pdf_app?id=${id}&token=${token}`
    );
    return response.data.link
  }
);

const initialState = {
  list: [],
  loading: false,
  bookType: "shopItem",
  item: {},
  pdf: "",
  pdfLoading: false
};

export const books = createSlice({
  name: "books",
  initialState,
  reducers: {
    setBookItem: (state, action) => {
      state.item = action.payload;
    },
    setBookType: (state, action) => {
      state.bookType = action.payload;
    },
    setBooksLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearBooks: (state, action) => {
      state.list = [];
    },
    setPdfLoading: (state, action) => {
      state.pdfLoading = true
    }
  },
  extraReducers: {
    [fetchBooks.fulfilled]: (state, action) => {
      state.list = action.payload.map((item) => {
        return { ...item };
      });
      state.loading = false;
    },
    [fetchBookPdf.fulfilled]: (state, action) => {
      state.pdf = action.payload
      state.pdfLoading = false
    },
  },
});

export const { setBooksLoading, clearBooks, setBookItem, setBookType, setPdfLoading } =
  books.actions;
export default books.reducer;
