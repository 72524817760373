export const checkCategory = (cat) => {
  if(cat === 'Amrap') {
    return 'Amrap'
  } else if (cat === "Adome,Gambe,Glutei") {
    return "Addome, Gambe, Glutei"
  } else if (cat === "Tabata") {
    return 'Tabata'
  } else if (cat === "PHA") {
    return "PHA"
  } else if (cat === "Burn Workout") {
    return "Burn Workout"
  } else if (cat === "Full Body Day") {
    return 'Full Body Day'
  } else if (cat === "Full Body Circuito") {
    return 'Full Body Circuito'
  } else if (cat === "Upper Body") {
    return "Upper Body"
  } else if (cat === "Glutei, Cosce") {
    return 'Glutei, Cosce'
  } else if (cat === "Glutei,Braccia") {
    return 'Glutei, Braccia'
  } else if (cat === "Spale,Dorso") {
    return 'Spalle, Dorso'
  } else {
    return 'Category'
  }
};

export const alimentiCategory = (cat) => {
  if(cat === 'Cer/Deriv') {
    return 'Ceriali e Derivanti'
  } else if (cat === 'Leg') {
    return 'Legumi'
  } else if (cat === 'Verd') {
    return 'Verdure'
  } else if (cat === 'Frutta') {
    return 'Frutta'
  } else if (cat === 'Carne') {
    return 'Carni'
  } else if (cat === 'Carni Tran') {
    return 'Carni Transformate'
  } else if (cat === 'Frattaglie') {
    return 'Frattaglie'
  } else if (cat === 'Latte e Yogurt') {
    return 'Latte e Yoghurt'
  } else if (cat === 'Formaggi') {
    return 'Formaggi'
  } else if (cat === 'Upva') {
    return 'Uova'
  } else if (cat === 'Grassi/Cond') {
    return 'Grassi e Condimenti'
  } else if (cat === 'Dolci') {
    return 'Dolci'
  } else if (cat === 'Prodotti Vari') {
    return 'Prodotti Vari'
  } else if (cat === 'Alcool') {
    return 'Alcool'
  } else if (cat === 'Bevande e Bibite') {
    return 'Bevande e Bibite'
  } else {
    return cat
  }
}
