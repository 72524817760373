import React from "react";
import { HiPlus } from "react-icons/hi";
import Colors from "../constants/Colors";

export default function AddDish(props) {
  return (
    <div
      onClick={props.onClick}
      className="d-flex justify-content-center align-items-center"
      style={{
        width: 200,
        height: 230,
        borderWidth: 1,
        borderColor: Colors.gray,
        borderStyle: "solid",
        borderRadius: 8,
        flexWrap: "nowrap",
        flexShrink: 0,
        margin: 5,
        cursor: 'pointer'
      }}
    >
      <HiPlus size={20} color={Colors.gray} />
    </div>
  );
}
