import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";
import moment from "moment";

export const fetchUserData = createAsyncThunk("profile/me", async function () {
  const { data } = await api.get("/profile/me");
  return data;
});

export const fetchParams = createAsyncThunk(
  "profile/params",
  async function () {
    const { data } = await api.post("/profile/check_params3");
    return data.data;
  }
);

export const fetchPersonalProduct = createAsyncThunk(
  "profile/personal_product",
  async function () {
    const { data } = await api.get("/profile/personal_product");
    return data.data;
  }
);

export const fetchSessione = createAsyncThunk(
  "profile/sessione",
  async function () {
    const { data } = await api.get("/profile/personal_exercise2");
    return data.data;
  }
);

export const fetchConsultantData = createAsyncThunk(
  "profile/consultant",
  async function () {
    const { data } = await api.get("/profile/my_consultant");
    return data.consultant;
  }
);

export const fetchPersonalDish = createAsyncThunk(
  "profile/personal_dish",
  async function () {
    const { data } = await api.get("/profile/personal_dish");
    return data.data;
  }
);

export const fetchAnamnesiQuestion = createAsyncThunk(
  "profile/question_all",
  async function () {
    const { data } = await api.get("/profile/question_all");
    return data.list;
  }
);

export const fetchAnamnesiId = createAsyncThunk(
  "profile/new_anamnesis",
  async function () {
    const { data } = await api.post("/profile/new_anamnesis");
    return data.id;
  }
);

export const fetchAnamnesiList = createAsyncThunk(
  "question/my_anamnesis_list",
  async function () {
    const { data } = await api.get("/question/my_anamnesis_list");
    return data.list;
  }
);

export const fetchInvoiceList = createAsyncThunk(
  "profile/my_invoices",
  async function () {
    const { data } = await api.get("/profile/my_invoices");
    return data.list;
  }
);

export const fetchNextCheck = createAsyncThunk(
  "profile/myNextCheck",
  async function () {
    const { data } = await api.get("/profile/myNextCheck");
    return data.next_check;
  }
);

const checkItem = (arr, item) => {
  const ids = [];
  arr.map((el) => {
    ids.push(el.id);
  });

  const index = ids.indexOf(item.id);

  if (index > -1) {
    arr.splice(index, 1);
    arr.push(item);
  } else {
    arr.push(item);
  }

  arr.sort((a, b) => a.id - b.id);
};

const initialState = {
  user: {},
  userType: null,
  userStatus: null,
  userImage: null,
  userContract: null,
  registrationEmail: null,
  registrationCode: null,
  sessione: null,
  sessioneActive: {
    exerciziItem: null,
    index: null,
    sessioneIndex: null,
  },
  sessioneLoading: true,
  sessioneCategory: {},
  sessioneCategoryLoading: true,
  userLoading: true,
  paramsLoading: true,
  consultantLoading: true,
  params: {},
  consultant: {},
  personalProducts: [],
  personalDish: null,
  originalPersonalDish: null,
  personalDishLoading: true,
  refeedDay: false,
  anamnesiQuestion: [],
  anamnesiLoading: true,
  anamnesiId: null,
  anamnesiIdLoading: true,
  anamnesiSummary: [],
  anamnesiList: [],
  invoiceList: [],
  invoiceLoading: true,
  nextCheck: null,
};

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserLoading: (state, action) => {
      state.userLoading = action.payload;
    },
    setRegistrationEmail: (state, action) => {
      state.registrationEmail = action.payload;
    },
    setRegistrationCode: (state, action) => {
      state.registrationCode = action.payload;
    },
    setParamsLoading: (state, action) => {
      state.paramsLoading = action.payload;
    },
    clearParams: (state, action) => {
      state.params = {};
    },
    setConsultantLoading: (state, action) => {
      state.consultantLoading = action.payload;
    },
    setSessioneLoading: (state, action) => {
      state.sessioneLoading = action.payload;
    },
    setSessioneCategory: (state, action) => {
      state.sessioneCategory = action.payload;
      state.sessioneCategoryLoading = false;
    },
    setSessioneCategoryLoading: (state, action) => {
      state.sessioneCategoryLoading = action.payload;
    },
    setPersonalDishLoading: (state, action) => {
      state.personalDishLoading = action.payload;
    },
    emptyPersonalDish: (state, action) => {
      state.personalDish = null;
      state.originalPersonalDish = null
    },
    sessioneActive: (state, action) => {
      state.sessioneActive.exerciziItem = action.payload.exerciziItem;
      state.sessioneActive.index = action.payload.index;
      state.sessioneActive.sessioneIndex = action.payload.sessioneIndex;
    },
    setAnamnesiSummary: (state, action) => {
      checkItem(state.anamnesiSummary, action.payload);
    },
    clearAnamnesiSummary: (state, action) => {
      state.anamnesiSummary = [];
    },
  },
  extraReducers: {
    [fetchUserData.fulfilled]: (state, action) => {
      state.user = action.payload.data;
      state.userImage = action.payload.data.image;
      state.userType = action.payload.client_type;
      state.userStatus = action.payload.status;
      state.userContract = action.payload.contract;
      state.userLoading = false;
    },
    [fetchParams.fulfilled]: (state, action) => {
      state.params = action.payload;
      state.paramsLoading = false;
    },
    [fetchSessione.fulfilled]: (state, action) => {
      state.sessione = action.payload;
      state.sessioneLoading = false;
    },
    [fetchConsultantData.fulfilled]: (state, action) => {
      state.consultant = action.payload;
      state.consultantLoading = false;
    },
    [fetchPersonalDish.fulfilled]: (state, action) => {
      const options = action.payload?.options;
      if (
        action.payload.strategies === "Metodo Misto" &&
        options &&
        options.lastUpdate &&
        options.lastUpdate === moment().format("YYYY-MM-DD")
      ) {
        state.refeedDay = true;
        state.personalDish = {
          ...action.payload,
          macro_carbo: options.free_carb,
          macro_grasi: options.free_gras,
          macro_prote: options.free_prot,
          macro_kcal: options.free_kcall,
        };
      } else {
        state.refeedDay = false;
        state.personalDish = action.payload;
      }

      state.originalPersonalDish = action.payload;
      state.personalDishLoading = false;
    },
    [fetchPersonalProduct.fulfilled]: (state, action) => {
      state.personalProducts = action.payload;
    },
    [fetchAnamnesiQuestion.fulfilled]: (state, action) => {
      state.anamnesiQuestion = action.payload;
      state.anamnesiLoading = false;
    },
    [fetchAnamnesiId.fulfilled]: (state, action) => {
      state.anamnesiId = action.payload;
      state.anamnesiIdLoading = false;
    },
    [fetchAnamnesiList.fulfilled]: (state, action) => {
      state.anamnesiList = action.payload;
    },
    [fetchInvoiceList.fulfilled]: (state, action) => {
      state.invoiceList = action.payload;
      state.invoiceLoading = false;
    },
    [fetchNextCheck.fulfilled]: (state, action) => {
      state.nextCheck = action.payload;
    }
  },
});

export const {
  setUserLoading,
  setRegistrationEmail,
  setRegistrationCode,
  setParamsLoading,
  setPersonalDishLoading,
  emptyPersonalDish,
  setConsultantLoading,
  sessioneActive,
  clearParams,
  setSessioneLoading,
  setSessioneCategory,
  setSessioneCategoryLoading,
  setAnamnesiSummary,
  clearAnamnesiSummary,
} = profile.actions;
export default profile.reducer;
