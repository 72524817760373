import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import IgnoreHeader from "../components/IgnoreHeader";
import { InputCheck } from "../components/Input";
import { api } from "../api/api";
import { fetchUserData } from "../redux/profile";
import Card from "../components/Card";
import Popup from "../components/Popup";

export default function Termini() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.profile.user);
  const userImage = useSelector((state) => state.profile.userImage);
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [age, setAge] = useState();
  const [address, setAddress] = useState();
  const [comune, setComune] = useState();
  const [provincia, setProvincia] = useState();
  const [cap, setCap] = useState();
  const [codiceFiscale, setCodiceFiscale] = useState();
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const checkFields = () => {
    if (
      !!name &&
      !!phone &&
      !!email &&
      !!age &&
      !!address &&
      !!comune &&
      !!provincia &&
      !!cap &&
      !!codiceFiscale
    ) {
      updateProfile();
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENZIONE",
        message: "Tutti i campi sono obbligatori!",
        button: "Riprova",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => {
          setShowPopup({ visible: false });
        },
      });
    }
  };

  const updateProfile = () => {
    const jsonObject = {
      json_object: {
        alimentazione: user.alimentazione,
        allenameto: user.allenameto,
        name: name,
        phone: phone,
        email: email,
        age: age,
        address: address,
        comune: comune,
        provincia: provincia,
        cap: cap,
        codice: codiceFiscale,
        image: userImage || 0,
      },
      image: userImage || 0,
    };

    api
      .post("/profile/update_me", jsonObject)
      .then(() => {
        dispatch(fetchUserData());
        navigate("accept");
      })
      .catch((error) => {
        console.log(error);
        setShowPopup({
          visible: true,
          heading: "Errore di connessione",
          message: "Prova ad accedere da un punto di accesso delle reti mobili",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            setShowPopup({ visible: false });
          },
        });
      });
  };

  return (
    <IgnoreHeader>
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <div className="container">
        <div className="termini-wrapper">
          <Card>
          <div>
            <InputCheck
              description="Nome e Cognome"
              onChange={(e) => setName(e.target.value)}
            />
            <InputCheck
              description="Telefono"
              onChange={(e) => setPhone(e.target.value)}
            />
            <InputCheck
              description="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
             <InputCheck
              description="Eta"
              onChange={(e) => setAge(e.target.value)}
            />
            <InputCheck
              description="Indirizzo"
              onChange={(e) => setAddress(e.target.value)}
            />
            <InputCheck
              description="Comune"
              onChange={(e) => setComune(e.target.value)}
            />
            <div className="d-flex justify-content-center">
              <InputCheck
                description="Provincia"
                onChange={(e) => setProvincia(e.target.value)}
                style={{marginRight: 5}}
              />
              <InputCheck
                description="Cap"
                onChange={(e) => setCap(e.target.value)}
                style={{marginLeftt: 5}}
              />
            </div>
            <InputCheck
              description="Codice Fiscale"
              onChange={(e) => setCodiceFiscale(e.target.value)}
            />
          </div>
          <Button
            type="primary"
            name="Next"
            onClick={() => {
              checkFields();
            }}
            style={{marginTop: 30}}
          />
          </Card>
        </div>
      </div>
    </IgnoreHeader>
  );
}
