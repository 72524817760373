import React from "react";
import Card from "./Card";
import ImageRender from "./ImageRender";
import { truncateString } from "../helper/truncateString";
import Colors from "../constants/Colors";

export default function ProductBookCard(props) {
  return (
    <Card style={{ padding: 0, width: 250, overflow: "hidden", marginLeft: 5, marginRight: 5 }}>
      <div className="productBook">
        <div className="productBook-image">
          <ImageRender idImg={props.idImg} label={props.label} color={Colors.orange} />
        </div>
        <div className="productBook-info">
          <p className="productBook-info-heading">
            {props.name && truncateString(props.name, 30)}
          </p>
        </div>
      </div>
    </Card>
  );
}
