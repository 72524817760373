import React from "react";

export default function RadioButton(props) {
  return (
    <div className="radioButton d-flex align-items-center">
      <input
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        type="radio"
        checked={props.checked}
        className="radioButton-input"
      />
      <label htmlFor={props.id} className="radioButton-label">
        {props.label}
      </label>
    </div>
  );
}
