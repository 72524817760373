import React, { useState, useEffect, useRef } from "react";
import { HiX } from "react-icons/hi";
import Card from "./Card";
import Button from "./Button";
import Colors from "../constants/Colors";
import { InputCheck } from "./Input";
import { api, apiUrl } from "../api/api";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchParams,
  setParamsLoading,
  fetchNextCheck,
} from "../redux/profile";
import ImagePicker from "./ImagePicker";
import Popup from "../components/Popup";
import VideoPlayer from "./VideoPlayer";
import { IoPlayOutline } from "react-icons/io5";

export default function CheckModal(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const params = useSelector((state) => state.profile.params);
  const [collo, setCollo] = useState("");
  const [braccioRight, setBraccioRight] = useState("");
  const [braccioLeft, setBraccioLeft] = useState("");
  const [vita, setVita] = useState("");
  const [fianchi, setFianchi] = useState("");
  const [cosciaRight, setCosciaRight] = useState("");
  const [cosciaLeft, setCosciaLeft] = useState("");
  const [kg, setKg] = useState("");
  const [frontImg, setFrontImg] = useState();
  const [sideImg, setSideImg] = useState();
  const [backImg, setBackImg] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });
  const [videoVisible, setVideoVisible] = useState(false);
  const checkTutorial = useSelector((state) => state.tutorial.checkTutorial);

  const modalRef = useRef(null);

  const parsedDescription = checkTutorial
    ? JSON.parse(checkTutorial.content)
    : null;

  const updateCheck = () => {
    api
      .post(`/profile/${props.edit ? "update_check_v3" : "add_check_v3"}`, {
        collo: collo,
        braccio: 0,
        baccioRight: braccioRight,
        baccioLeft: braccioLeft,
        vita: vita,
        fianchi: fianchi,
        coscia: 0,
        cosciaRight: cosciaRight,
        cosciaLeft: cosciaLeft,
        kg: kg,
        massa_grassa: 0,
        front_img: frontImg,
        side_img: sideImg,
        back_img: backImg,
        id: params?.all[params?.all?.length - 1]?.id || 0,
      })
      .then((response) => {
        console.log("check updated");
        setShowPopup({
          visible: true,
          heading: "FATTO",
          message: "Il tuo check è stato salvato con successo!",
          button: "OK",
          onClose: () => {
            setShowPopup({ visible: false });
            handleClose();
          },
          onClick: () => {
            dispatch(setParamsLoading(true));
            dispatch(fetchParams());
            dispatch(fetchNextCheck());
            setShowPopup({ visible: false });
            handleClose();
          },
        });
      })
      .catch((err) => {
        setShowPopup({
          visible: true,
          heading: "ERRORE",
          message: "Prova piu tardi!",
          button: "OK",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        });
        console.log("err", err.message);
      });
  };

  const uploadImage = async (checkImg, id) => {
    setImageLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("img[]", checkImg, checkImg.name);
    formdata.append("type", "checkParam");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/img/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (id === 1) {
          setFrontImg(data.id.response[0]);
        } else if (id === 2) {
          setSideImg(data.id.response[0]);
        } else if (id === 3) {
          setBackImg(data.id.response[0]);
        }
        setImageLoading(false);
      })
      .catch((error) => {
        console.log("error uploading image", error);
        setImageLoading(false);
      });
  };

  const checkValidity = () => {
    if (
      !!collo &&
      !!braccioRight &&
      !!braccioLeft &&
      !!vita &&
      !!fianchi &&
      !!cosciaRight &&
      !!cosciaLeft &&
      !!kg &&
      !!frontImg &&
      !!sideImg &&
      !!backImg
    ) {
      updateCheck();
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENZIONE",
        message: "Tutti i campi sono obbligatori!",
        button: "Riprova",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => setShowPopup({ visible: false }),
      });
    }
  };

  const replaceSymbols = (value) => {
    const newValue = value.replace(",", ".");
    const dots = newValue.split(".").length - 1;
    if (
      newValue.includes(",") ||
      newValue.includes("-") ||
      dots > 1 ||
      newValue === "." ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === "-" ||
      newValue === "--"
    ) {
      return "";
    } else {
      return newValue;
    }
  };

  const handleClose = () => {
    modalRef.current.setAttribute("data-bs-dismiss", "modal");
    modalRef.current.click();
  };

  return (
    <div className="check">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <a type="button" data-bs-toggle="modal" data-bs-target="#checkModal">
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="checkModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable check-modal-dialog">
          <div className="modal-content">
            <div className="modal-header check-modal-header justify-content-center">
              <button
                type="button"
                className="check-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalRef}
              >
                <HiX size={30} />
              </button>
              <p className="check-title">Aggiungi Check</p>
            </div>
            <div className="modal-body">
              <div className="check-body-wrapper">
                {videoVisible && (
                  <Card style={{ padding: 0 }}>
                    <VideoPlayer link={parsedDescription?.cont[0].link} />
                  </Card>
                )}
                <Card>
                  <div
                    className="d-flex align-items-center justify-content-center allenamenti-video-intro"
                    onClick={() => setVideoVisible(!videoVisible)}
                    style={{ cursor: "pointer" }}
                  >
                    <p className="allenamenti-video-text">
                      {videoVisible ? "Nascondi Video" : "Video Introduzione"}
                    </p>
                    <IoPlayOutline size={25} color={Colors.primary} />
                  </div>
                </Card>
                <div className="row justify-content-md-center">
                  <div className="col-8 col-md-12">
                    <Card>
                      <p className="heading heading-card">
                        Carica la tua foto Frontale
                      </p>
                      <div className="check-image-wrapper align-self-center">
                        <ImagePicker
                          onImageTaken={(imagePath) =>
                            imagePath && uploadImage(imagePath, 1)
                          }
                        />
                      </div>
                    </Card>
                    <Card>
                      <p className="heading heading-card">
                        Carica la tua foto Laterale
                      </p>
                      <div className="check-image-wrapper align-self-center">
                        <ImagePicker
                          onImageTaken={(imagePath) =>
                            imagePath && uploadImage(imagePath, 2)
                          }
                        />
                      </div>
                    </Card>
                    <Card>
                      <p className="heading heading-card">
                        Carica la tua foto Spalle
                      </p>
                      <div className="check-image-wrapper align-self-center">
                        <ImagePicker
                          onImageTaken={(imagePath) =>
                            imagePath && uploadImage(imagePath, 3)
                          }
                        />
                      </div>
                    </Card>
                  </div>
                  <div className="col-4 col-lg-8 col-md-12">
                    <Card>
                      <p className="heading heading-card">
                        Peso e circonferenze corporee
                      </p>
                      <InputCheck
                        number
                        placeholder="esempio 70 kg"
                        description="peso corpo (kg)*"
                        onChange={(e) => setKg(replaceSymbols(e.target.value))}
                        maxLength={6}
                      />
                      <InputCheck
                        number
                        placeholder="esempio 70 cm"
                        description="collo (cm)*"
                        onChange={(e) =>
                          setCollo(replaceSymbols(e.target.value))
                        }
                        maxLength={6}
                      />
                      <InputCheck
                        number
                        placeholder="esempio 70 cm"
                        description="braccio d (cm)*"
                        onChange={(e) =>
                          setBraccioLeft(replaceSymbols(e.target.value))
                        }
                        maxLength={6}
                      />
                      <InputCheck
                        number
                        placeholder="esempio 70 cm"
                        description="braccio s (cm)*"
                        onChange={(e) =>
                          setBraccioRight(replaceSymbols(e.target.value))
                        }
                        maxLength={6}
                      />
                      <InputCheck
                        number
                        placeholder="esempio 70 cm"
                        description="vita (cm)*"
                        onChange={(e) =>
                          setVita(replaceSymbols(e.target.value))
                        }
                        maxLength={6}
                      />
                      <InputCheck
                        number
                        placeholder="esempio 70 cm"
                        description="fianchi (cm)*"
                        onChange={(e) =>
                          setFianchi(replaceSymbols(e.target.value))
                        }
                        maxLength={6}
                      />
                      <InputCheck
                        number
                        placeholder="esempio 70 cm"
                        description="coscia d (cm)*"
                        onChange={(e) =>
                          setCosciaRight(replaceSymbols(e.target.value))
                        }
                        maxLength={6}
                      />
                      <InputCheck
                        number
                        placeholder="esempio 70 cm"
                        description="coscia s (cm)*"
                        onChange={(e) =>
                          setCosciaLeft(replaceSymbols(e.target.value))
                        }
                        maxLength={6}
                      />
                    </Card>
                    <Button
                      type={"primary"}
                      name={imageLoading ? "Carico le Foto" : "Fatto"}
                      style={{
                        marginTop: 20,
                        backgroundColor: imageLoading
                          ? Colors.primaryLightShadow
                          : Colors.primary,
                      }}
                      onClick={() => !imageLoading && checkValidity()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
