import React, { useEffect, useState, useRef } from "react";
import IgnoreHeader from "../components/IgnoreHeader";
import Card from "../components/Card";
import ImageRender from "../components/ImageRender";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Label from "../components/Label";
import Colors from "../constants/Colors";
import ProductVideoCard from "../components/ProductVideoCard";
import { useNavigate } from "react-router-dom";
import {
  HiChevronLeft,
  HiOutlineArrowCircleLeft,
  HiOutlineArrowCircleRight,
} from "react-icons/hi";
import Button from "../components/Button";

export default function CourseDetails() {
  const scrollRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course = useSelector((state) => state.videoCourse.item);
  const videoCourseLoading = useSelector((state) => state.videoCourse.loading);
  const videoCourseType = useSelector((state) => state.videoCourse.courseType);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const divElement = scrollRef.current;
    if (divElement) {
      divElement.scroll({ left: scroll, behavior: "smooth" });
    }
  }, [scroll]);

  if (videoCourseLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={videoCourseLoading} size={50} />
      </div>
    );
  }

  if (!course) return <p style={{ padding: 50 }}>No course found</p>;

  if (course?.json_object.length === 0) {
    return <p style={{ padding: 47 }}>An ERROR occured!</p>;
  }

  const sessione = course?.json_object;

  return (
    <IgnoreHeader>
      <div className="container courseDetails pb-3">
        <Card>
          <div className="d-flex align-items-center">
            <HiChevronLeft color={Colors.primary} size={20} />
            <p className="courseDetails-nav" onClick={() => navigate(-1)}>
              Prodotti Extra
            </p>
          </div>
          <div className="courseDetails-wrapper mt-2">
            <div className="d-flex align-items-center mb-3">
              <p className="heading me-3">{course.name}</p>
              <Label labelText={"Video Corso"} labelColor={Colors.primary} />
            </div>
            <Card style={{ padding: 0, overflow: "hidden", height: 430 }}>
              <ImageRender idImg={course.image} />
            </Card>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="d-flex">
                <div className="allenamenti-items">
                  <p className="allenamenti-items-text">Contenuti</p>
                  <p className="allenamenti-items-content">
                    {course.contenuti || 0}
                  </p>
                </div>
                <div className="allenamenti-items">
                  <p className="allenamenti-items-text">video</p>
                  <p className="allenamenti-items-content">
                    {course.count_video || 0}
                  </p>
                </div>
              </div>
              {/* {course.start_price > 0 && (
                <div className="d-flex align-items-center">
                  {course.discount > 0 && (
                    <div className="courseDetails-items me-4">
                      <p className="courseDetails-items-text">sconto</p>
                      <p className="courseDetails-items-content">
                        {`${course.discount}%`}
                      </p>
                    </div>
                  )}
                  <p className="courseDetails-info-price">{`€${course.final_price}`}</p>
                  {course.start_price > course.final_price && (
                    <p className="productVideo-info-firstPrice">{`al posto di €${course.start_price}`}</p>
                  )}
                </div>
              )} */}
            </div>
            <Card style={{ padding: 0 }}>
              <HiOutlineArrowCircleLeft
                size={30}
                color={Colors.primary}
                className="courseDetails-arrow courseDetails-arrow-left"
                onClick={() => setScroll(scroll - 250)}
              />
              <div
                className="d-flex p-3 pt-1"
                style={{ overflowX: "scroll" }}
                ref={scrollRef}
              >
                {sessione.length > 0 &&
                  sessione.map((item, index) => (
                    <div key={index}>
                      <ProductVideoCard
                        idImg={item.image}
                        label={"contenuti"}
                        name={item.name || ""}
                        video={`video ${index + 1}`}
                      />
                    </div>
                  ))}
              </div>
              <HiOutlineArrowCircleRight
                size={30}
                color={Colors.primary}
                className="courseDetails-arrow courseDetails-arrow-right"
                onClick={() => setScroll(scroll + 250)}
              />
            </Card>
            <Card>
              <p className="heading heading-card">Descrizione</p>
              <div dangerouslySetInnerHTML={{ __html: course.description }} />
            </Card>
            <div className="mt-3">
              <Button
                type={"primary"}
                name={"Richiedi Informazioni"}
                onClick={() => navigate("../../percorso/consultant")}
              />
            </div>
          </div>
        </Card>
      </div>
    </IgnoreHeader>
  );
}
