import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNextCheck, fetchParams } from "../redux/profile";
import Card from "../components/Card";
import Button from "../components/Button";
import ProgressCircle from "../components/ProgressCircle";
import Colors from "../constants/Colors";
import CheckFotoCard from "../components/CheckFotoCard";
import Loader from "../components/Loader";
import moment from "moment";
import "moment/locale/it";
import CheckModal from "../components/CheckModal";
import { HiPlus } from "react-icons/hi";
import { CgSandClock } from "react-icons/cg";
import { FiEdit3 } from "react-icons/fi";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function Parametri(props) {
  moment.locale("it");
  const dispatch = useDispatch();
  const params = useSelector((state) => state.profile.params);
  const paramsLoading = useSelector((state) => state.profile.paramsLoading);
  const myNextCheck = useSelector((state) => state.profile.nextCheck);

  useEffect(() => {
    dispatch(fetchParams());
    dispatch(fetchNextCheck());
  }, []);

  if (paramsLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={paramsLoading} size={50} />
      </div>
    );
  }

  if (!params?.first) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className=" mt-5"
      >
        <CheckModal
          openModal={<Button type={"primary"} name="+ Aggiungi Check" />}
        />
      </div>
    );
  }

  const today = moment();
  const isTodaySameOrAfterNextUpdate = today.isSameOrAfter(myNextCheck, "day");
  const lastCheck = moment(params?.last?.updated_at).format("L");

  const diffKg = params?.first?.kg_diff;

  const checkDiff = (nr) => {
    if (nr === 0 || !nr) {
      return "no changes";
    } else if (nr > 0) {
      return `+${nr}cm`;
    } else {
      return `${nr}cm`;
    }
  };

  const checkDiffKg = (nr) => {
    if (nr === 0 || !nr) {
      return "0";
    } else if (nr > 0) {
      return `+${nr}kg`;
    } else {
      return `${nr}kg`;
    }
  };

  const checkColor = (nr) => {
    if (nr === 0 || !nr) {
      return Colors.gray;
    } else if (nr > 0) {
      return Colors.red;
    } else {
      return Colors.green;
    }
  };

  const checkShadowColor = (nr) => {
    if (nr === 0 || !nr) {
      return Colors.grayLight;
    } else if (nr > 0) {
      return Colors.redLightShadow;
    } else {
      return Colors.greenLightShadow;
    }
  };

  return (
    <div className="container parametri pb-3">
      <Card>
        <div className="d-flex justify-content-between align-items-center">
          <p className="heading">Check Parametri</p>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-6 col-xl-6 col-lg-12 col-md-12">
            <div className="d-flex align-items-center justify-content-evenly">
              <div className="parametri-progress text-center">
                <p className="parametri-progress-heading">
                  parametri personali
                </p>
                <div className="d-flex">
                  <div className="parametri-progress-wrapper">
                    <ProgressCircle
                      value={100}
                      text={params?.first?.kg + "kg"}
                      color={Colors.primary}
                      textColor={Colors.primary}
                      shadowColor={Colors.primaryLightShadow}
                    />
                    <div className="parametri-progress-details text-center">
                      <p className="parametri-progress-subtext">primo check</p>
                    </div>
                  </div>
                  <div className="parametri-progress-wrapper">
                    <ProgressCircle
                      value={30}
                      text={checkDiffKg(diffKg)}
                      color={checkColor(diffKg)}
                      textColor={checkColor(diffKg)}
                      shadowColor={checkShadowColor(diffKg)}
                    />
                    <div className="parametri-progress-details text-center">
                      <p className="parametri-progress-subtext">ultimo check</p>
                    </div>
                  </div>
                </div>
              </div>
              {isTodaySameOrAfterNextUpdate ? (
                <div className="parametri-progress text-center">
                  <p className="parametri-progress-heading">Aggiungi Check</p>
                  <div className="d-flex">
                    <div className="parametri-progress-wrapper">
                      <CheckModal
                        openModal={
                          <CircularProgressbarWithChildren
                            styles={{ path: { stroke: Colors.green } }}
                          >
                            <HiPlus size={60} color={Colors.green} />
                          </CircularProgressbarWithChildren>
                        }
                      />
                      <div className="parametri-progress-details text-center">
                        <p className="parametri-progress-subtext">
                          check disponibile
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="parametri-progress text-center">
                  <p className="parametri-progress-heading">
                    Ultimo check {lastCheck}
                  </p>
                  <div className="d-flex">
                    <div className="parametri-progress-wrapper">
                      <CircularProgressbarWithChildren
                        styles={{ path: { stroke: Colors.primary } }}
                      >
                        <CgSandClock size={60} color={Colors.primary} />
                      </CircularProgressbarWithChildren>
                      <div className="parametri-progress-details text-center">
                        <p className="parametri-progress-subtext">
                          prossimo {moment(myNextCheck).format("L")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-6 col-xl-6 col-lg-12 col-md-12">
            <div className="parametri-items d-flex align-items-center justify-content-evenly mb-2">
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Collo</p>
                <p className="parametri-items-value">{`${params?.last?.collo}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.collo_diff) }}
                >
                  {params.first.collo_diff
                    ? checkDiff(parseFloat(params.first.collo_diff).toFixed(1))
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Braccio D</p>
                <p className="parametri-items-value">{`${params?.last?.baccioRight}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.baccioRight_diff) }}
                >
                  {params.first.baccioRight_diff
                    ? checkDiff(
                        parseFloat(params.first.baccioRight_diff).toFixed(1)
                      )
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Braccio S</p>
                <p className="parametri-items-value">{`${params?.last?.baccioLeft}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.baccioLeft_diff) }}
                >
                  {params.first.baccioLeft_diff
                    ? checkDiff(
                        parseFloat(params.first.baccioLeft_diff).toFixed(1)
                      )
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Vita</p>
                <p className="parametri-items-value">{`${params.last.vita}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.vita_diff) }}
                >
                  {params.first.vita_diff
                    ? checkDiff(parseFloat(params.first.vita_diff).toFixed(1))
                    : ""}
                </p>
              </div>
            </div>
            <div className="parametri-items d-flex align-items-center justify-content-evenly">
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Fianchi</p>
                <p className="parametri-items-value">{`${params.last.fianchi}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.fianchi_diff) }}
                >
                  {params.first.fianchi_diff
                    ? checkDiff(
                        parseFloat(params.first.fianchi_diff).toFixed(1)
                      )
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Coscia D</p>
                <p className="parametri-items-value">{`${params.last.cosciaRight}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.cosciaRight_diff) }}
                >
                  {params.first.cosciaRight_diff
                    ? checkDiff(
                        parseFloat(params.first.cosciaRight_diff).toFixed(1)
                      )
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Coscia S</p>
                <p className="parametri-items-value">{`${params.last.cosciaLeft}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.cosciaLeft_diff) }}
                >
                  {params.first.cosciaLeft_diff
                    ? checkDiff(
                        parseFloat(params.first.cosciaLeft_diff).toFixed(1)
                      )
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {(params.first.notes || params.last.notes) && (
        <Card>
          <p className="heading heading-card">Note</p>
          <p className="contentText">
            {params.first.notes || params.last.notes}
          </p>
        </Card>
      )}
      <Card>
        <div className="d-flex justify-content-between align-items-center">
          <p className="heading">Check {lastCheck}</p>
          <CheckModal
            openModal={
              <Card style={{ margin: 0 }}>
                <div className="d-flex">
                  <FiEdit3
                    size={20}
                    color={Colors.primary}
                    style={{ marginRight: 10 }}
                  />
                  <p className="allenamenti-video-text">
                    Modifica l'ultimo check
                  </p>
                </div>
              </Card>
            }
            edit
          />
        </div>
        <div>
          <CheckFotoCard
            heading={"Check Foto Frontale"}
            sourceLeft={params?.first?.front_img}
            sourceMiddle={params?.beforeLast?.front_img}
            sourceRight={params?.last?.front_img}
            beforeLastDate={moment(params?.beforeLast?.updated_at).format("L")}
          />
          <CheckFotoCard
            heading={"Check Foto Laterale"}
            sourceLeft={params?.first?.side_img}
            sourceMiddle={params?.beforeLast?.side_img}
            sourceRight={params?.last?.side_img}
            beforeLastDate={moment(params?.beforeLast?.updated_at).format("L")}
          />
          <CheckFotoCard
            heading={"Check Foto Spalle"}
            sourceLeft={params?.first?.back_img}
            sourceMiddle={params?.beforeLast?.back_img}
            sourceRight={params?.last?.back_img}
            beforeLastDate={moment(params?.beforeLast?.updated_at).format("L")}
          />
        </div>
      </Card>
    </div>
  );
}
