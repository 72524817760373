import React, { useState } from "react";
import IgnoreHeader from "../components/IgnoreHeader";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import { api } from "../api/api";
import Popup from "../components/Popup";
import { fetchUserData } from "../redux/profile";
import { useNavigate } from "react-router-dom";
import Card from "../components/Card";

export default function TerminiAccept() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.profile.user);
  const legacyAll = useSelector((state) => state.legacy.list);
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const contractContent = legacyAll.find(
    (item) => item.category === "Contract"
  );

  const submitTermini = () => {
    api
      .get(
        `/profile/contract?clientName=${user.name}&fisc=${user.codice}&adr1=${user.address}&adr2=${user.comune}`
      )
      .then(() => {
        setShowPopup({
          visible: true,
          heading: "FATTO",
          message: "Grazie per avere preso la visione e accettato i termini e le condizioni.",
          button: "OK",
          onClose: () => {
            dispatch(fetchUserData());
            setShowPopup({ visible: false });
            navigate(-2);
          },
          onClick: () => {
            dispatch(fetchUserData());
            setShowPopup({ visible: false });
            navigate(-2);
          },
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <IgnoreHeader>
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <div className="container">
        <div className="terminiAccept-wrapper">
          <Card>
            <div
              dangerouslySetInnerHTML={{ __html: contractContent.content }}
            />
            <Button
              type="primary"
              name="Dichiaro di aver letto e accetto i termini e condizioni del contratto"
              onClick={submitTermini}
            />
          </Card>
        </div>
      </div>
    </IgnoreHeader>
  );
}
