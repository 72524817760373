import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceList } from "../redux/profile";
import Loader from "../components/Loader";
import IgnoreHeader from "../components/IgnoreHeader";
import Card from "../components/Card";
import { FaqCard } from "./Faq";
import { apiUrl } from "../api/api";
import Colors from "../constants/Colors";

export default function Invoices() {
  const dispatch = useDispatch();
  const invoiceList = useSelector((state) => state.profile.invoiceList);
  const invoiceLoading = useSelector((state) => state.profile.invoiceLoading);

  useEffect(() => {
    dispatch(fetchInvoiceList());
  }, []);

  if (invoiceLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={invoiceLoading} size={50} />
      </div>
    );
  }

  if(!invoiceList || invoiceList.length >= 0) {
    return (
      <div className="d-flex justify-content-center mt-5" style={{paddingTop: 150}}>
        <p className="heading">Non hai fatture disponibili al momento</p>
      </div>
    )
  }

  return (
    <IgnoreHeader>
      <div className="notifications-wrapper">
        <Card>
          {invoiceList.map((item, index) => (
            <a
              href={`${apiUrl}/login/my_invoices_dw/?id=${item.link_pdf}`}
              target="_blank"
              key={index}
            >
              <FaqCard
                heading={`Fattura ${index + 1}`}
                description={item.date}
                style={{
                  backgroundColor: Colors.white,
                }}
                textStyle={{ color: Colors.black }}
              />
            </a>
          ))}
        </Card>
      </div>
    </IgnoreHeader>
  );
}
