import React, { useEffect } from "react";
import { HiX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { fetchLegacyAll } from "../redux/legacy";

export default function PrivacyPolicyModal(props) {
  const dispatch = useDispatch();
  const legacy = useSelector((state) => state.legacy.list);

  useEffect(() => {
    dispatch(fetchLegacyAll());
  }, []);

  return (
    <div className="cookie">
      <a type="button" data-bs-toggle="modal" data-bs-target="#privacyModal">
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="privacyModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable cookie-modal-dialog">
          <div className="modal-content">
            <div className="modal-header cookie-modal-header justify-content-center">
              <button
                type="button"
                className="cookie-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="cookie-title">Privacy Policy</p>
            </div>
            <div className="modal-body">
              {legacy.length > 0 && legacy.map((item, index) => (
                <div key={index} dangerouslySetInnerHTML={{ __html: item.category === 'Privacy Policy' ? item.content : null}} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
