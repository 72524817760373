import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import profile from "./profile";
import tutorial from "./tutorial";
import legacy from "./legacy";
import exercise from "./exercise";
import dish from "./dish";
import books from "./books";
import food from "./food";
import videoCourse from "./videoCourses";
import menu from "./menu";
import filter from "./filter";
import favorites from "./favorites";
import notifications from "./notifications";

export const store = configureStore({
  reducer: {
    auth,
    dish,
    exercise,
    tutorial,
    profile,
    food,
    videoCourse,
    books,
    legacy,
    menu,
    filter,
    favorites,
    notifications
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 250 },
      serializableCheck: { warnAfter: 250 },
    }),
});
