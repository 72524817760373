import React from "react";
import { truncateString } from "../helper/truncateString";
import ImageRender from "./ImageRender";

export default function DishCard(props) {
  return (
    <div className="dishCard">
      <div className="dishCard-img-wrapper position-relative">
        <ImageRender idImg={props.img} label={props.labelText} color={props.labelColor} initialName={props.name} />
      </div>
      <div className="dishCard-info text-center">
        <p className="dishCard-info-name">
          {truncateString(props.name, 25) || ""}
        </p>
        <div className="d-flex justify-content-center">
          <div className="dishCard-item d-flex">
            <p className="dishCard-item-name">carb {props.carb || 0}</p>
            <span className="dishCard-item-name">|</span>
            <p className="dishCard-item-name">gras {props.gras || 0}</p>
            <span className="dishCard-item-name">|</span>
            <p className="dishCard-item-name">prot {props.prot || 0}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
