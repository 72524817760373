import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Label from "../components/Label";
import Colors from "../constants/Colors";
import { useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi";
import Card from "../components/Card";
import ImageRender from "../components/ImageRender";
import Button from "../components/Button";
import axios from "axios";
import { fetchBookPdf } from "../redux/books";

export default function MyBookDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const book = useSelector((state) => state.books.item);
  const bookType = useSelector((state) => state.books.bookType);
  const token = useSelector((state) => state.auth.token);
  const bookPdf = useSelector((state) => state.books.pdf);

  useEffect(() => {
    dispatch(fetchBookPdf(book.link, token));
  }, []);

  return (
    <div className="container bookDetails pb-3">
      <Card>
        <div className="d-flex align-items-center">
          <HiChevronLeft color={Colors.primary} size={20} />
          <p
            className="courseDetails-nav"
            onClick={() => navigate("/percorso/disponibili")}
          >
            Disponibili
          </p>
        </div>
        <div className="bookDetails-wrapper mt-2">
          <div className="d-flex align-items-center mb-3">
            <p className="heading me-3">{book.name}</p>
            <Label labelText={"Video Corso"} labelColor={Colors.primary} />
          </div>
          <div className="bookDetails-image">
            <Card style={{ padding: 0, overflow: "hidden", height: "100%" }}>
              <ImageRender idImg={book.image} />
            </Card>
          </div>
          <Card>
            <p className="heading heading-card">Descrizione</p>
            <div dangerouslySetInnerHTML={{ __html: book.description }} />
          </Card>
          <div className="mt-3">
            {book.type === "amazon" ? (
              <a href={book.link} target="blank">
                <Button type={"primary"} name={"Acquista su Amazon"} />
              </a>
            ) : (
              <a href={bookPdf} target="blank">
                <Button type={"primary"} name={"Apri"} />
              </a>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
