import React from "react";
import Card from "./Card";
import ImageRender from "./ImageRender";
import { truncateString } from "../helper/truncateString";
import Colors from "../constants/Colors";

export default function ProductVideoCard(props) {
  return (
    <Card
      style={{
        padding: 0,
        width: 400,
        overflow: "hidden",
        marginLeft: 5,
        marginRight: 5,
      }}
    >
      <div className="productVideo">
        <div className="productVideo-image">
          <ImageRender
            idImg={props.idImg}
            label={props.label}
            color={Colors.primary}
          />
        </div>
        <div className="productVideo-info d-flex justify-content-between align-items-center">
          <div>
            <p className="productVideo-info-heading">
              {props.name && truncateString(props.name, 30)}
            </p>
            <div className="d-flex align-items-center">
              {props.contenuti && (
                <p className="productVideo-info-subheading">
                  {props.contenuti}
                </p>
              )}
              {props.video && (
                <p className="productVideo-info-subheading">{props.video}</p>
              )}

              {props.discount && (
                <p className="productVideo-info-subheading">{props.discount}</p>
              )}
            </div>
          </div>
          <div>
            {props.price && (
              <p className="productVideo-info-price">{props.price}</p>
            )}
            {props.firstPrice && (
              <p className="productVideo-info-firstPrice">{props.firstPrice}</p>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
