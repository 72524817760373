import React, { useState } from "react";
import bg from "../assets/bg-fespa.webp";
import Input from "../components/Input";
import Button from "../components/Button";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { useDispatch } from "react-redux";
import { apiUrl } from "../api/api";
import { login } from "../redux/auth";
import { useNavigate } from "react-router-dom";
import Colors from "../constants/Colors";
import Popup from "../components/Popup";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

export default function Login(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const fetchUser = async () => {
    const subscription = setLoading(true);
    await axios
      .post(`${apiUrl}/login/login?email=${email}&password=${password}`)
      .then((response) => {
        dispatch(
          login({ token: response.data.token, role: response.data.type })
        );
        setLoading(false);
        navigate("/percorso/alimentazione");
      })
      .catch((err) => {
        setShowPopup({
          visible: true,
          heading: "ATTENZIONE",
          message: "Password o email sbagliata",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        });
        setLoading(false);
      });
    return () => {
      subscription.remove();
    };
  };

  return (
    <div className="login">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <img src={bg} alt="Fespa Background" className="login-bg" />
      <div className="login-wrapper">
        <p className="login-logo">fespa</p>
        <p className="login-description">Benvenuta nell’app ufficiale FESPA</p>
        <Input
          placeholder="Inserisci la tua email"
          hint="Log-in ricevuto sulla mail"
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
        <Input
          placeholder="Password"
          hint="la tua password"
          onChange={(e) => setPassword(e.target.value)}
          type={passwordShow ? "text" : "password"}
          autoComplete='true'
          icon={
            passwordShow ? (
              <HiOutlineEyeOff
                size="25"
                onClick={() => setPasswordShow(false)}
              />
            ) : (
              <HiOutlineEye size="25" onClick={() => setPasswordShow(true)} />
            )
          }
        />
        <Button
          name={
            loading ? (
              <ClipLoader color={"white"} loading={loading} size={20} />
            ) : (
              "Login"
            )
          }
          type="black"
          onClick={() => fetchUser()}
        />
      </div>
    </div>
  );
}
