import React, { useState, useEffect } from "react";
import IgnoreHeader from "../components/IgnoreHeader";
import Card from "../components/Card";
import ProductVideoCard from "../components/ProductVideoCard";
import ProductBookCard from "../components/ProductBookCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVideoCourseAll,
  fetchVideoCourseById,
  setCourseType,
  setVideoCourseLoading,
} from "../redux/videoCourses";
import { fetchPersonalProduct } from "../redux/profile";
import {
  fetchBooks,
  setBookItem,
  setBooksLoading,
  setBookType,
} from "../redux/books";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { HiSearch } from "react-icons/hi";
import Colors from "../constants/Colors";

export default function Extra() {
  const dispatch = useDispatch();
  const [corsi, setCorsi] = useState(true);
  const [libri, setLibri] = useState(false);

  useEffect(() => {
    dispatch(setBooksLoading(true));
    dispatch(setVideoCourseLoading(true));
    dispatch(fetchBooks());
    dispatch(fetchVideoCourseAll());
    dispatch(fetchPersonalProduct());
  }, []);

  return (
    <IgnoreHeader>
      <div className="container prodottiExtra">
        <Card>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={
                corsi
                  ? "prodottiExtra-nav prodottiExtra-nav-active"
                  : "prodottiExtra-nav"
              }
              onClick={() => {
                setCorsi(!corsi);
                setLibri(!libri);
              }}
            >
              <p
                className={
                  corsi
                    ? "prodottiExtra-nav-text prodottiExtra-nav-text-active"
                    : "prodottiExtra-nav-text"
                }
              >
                Corsi
              </p>
            </div>
            <div
              className={
                libri
                  ? "prodottiExtra-nav prodottiExtra-nav-active"
                  : "prodottiExtra-nav"
              }
              onClick={() => {
                setCorsi(!corsi);
                setLibri(!libri);
              }}
            >
              <p
                className={
                  libri
                    ? "prodottiExtra-nav-text prodottiExtra-nav-text-active"
                    : "prodottiExtra-nav-text"
                }
              >
                Libri
              </p>
            </div>
          </div>
        </Card>
        <div>{corsi ? <Corsi /> : <Libri />}</div>
      </div>
    </IgnoreHeader>
  );
}

const Corsi = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const videoCourse = useSelector((state) => state.videoCourse.list);
  const videoCourseLoading = useSelector((state) => state.videoCourse.loading);
  const personalProducts = useSelector(
    (state) => state.profile.personalProducts
  );

  const [search, setSearch] = useState("");

  if (videoCourseLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={videoCourseLoading} size={50} />
      </div>
    );
  }

  const getVideoList = () => {
    if (videoCourse.length < 0) return [];

    const myProductsIds = [];
    personalProducts.forEach((el) => {
      if (el.split("-")[0] === "Video Corsi") {
        myProductsIds.push(+el.split("-")[1]);
      }
    });

    const randomVideo = videoCourse.filter(
      (el) => !myProductsIds.includes(el.id)
    );

    if (search.length > 2) {
      return randomVideo.filter((item) => {
        if (item.hashtag) {
          return item.hashtag.toLowerCase().includes(search.toLowerCase());
        }
      });
    } else {
      return randomVideo;
    }
  };

  const setCourse = (course) => {
    dispatch(setVideoCourseLoading(true));
    dispatch(setCourseType("shopItem"));
    dispatch(fetchVideoCourseById(course.id));
    navigate("courseDetails");
  };

  return (
    <Card>
      <div className="prodottiExtra-wrapper">
        <div className="alimenti_search-container mt-3 d-flex align-items-center">
          <HiSearch
            size={20}
            color={Colors.primaryLight}
            style={{ marginRight: 10 }}
          />
          <input
            className="alimenti_input"
            placeholder="Cerca"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center justify-content-evenly flex-wrap">
          {getVideoList()?.length !== 0 &&
            getVideoList().map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setCourse(item);
                }}
              >
                <ProductVideoCard
                  idImg={item.image || ""}
                  label={"Video corso"}
                  name={item.name || ""}
                  contenuti={item.contenuti && `contenuti ${item.contenuti}`}
                  video={item.count_video && `video ${item.count_video}`}
                />
              </div>
            ))}
        </div>
      </div>
    </Card>
  );
};

const Libri = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const books = useSelector((state) => state.books.list);
  const booksLoading = useSelector((state) => state.books.loading);
  const personalProducts = useSelector(
    (state) => state.profile.personalProducts
  );

  if (booksLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={booksLoading} size={50} />
      </div>
    );
  }

  const getBooksList = () => {
    if (books.length < 0) return [];

    const myProductsIds = [];
    personalProducts.forEach((el) => {
      if (el.split("-")[0] === "Libri") {
        myProductsIds.push(+el.split("-")[1]);
      }
    });

    const randomBooks = books.filter((el) => !myProductsIds.includes(el.id));

    return randomBooks;
  };

  const setBook = (book) => {
    dispatch(setBookType("shopItem"));
    dispatch(setBookItem(book));
    navigate("bookDetails");
  };

  return (
    <Card>
      <div className="prodottiExtra-wrapper">
        <div className="d-flex align-items-center justify-content-evenly flex-wrap">
          {getBooksList()?.length !== 0 &&
            getBooksList().map((item, index) => (
              <div key={index} onClick={() => setBook(item)}>
                <ProductBookCard
                  idImg={item.image}
                  label={"Libro"}
                  name={item.name || ""}
                />
              </div>
            ))}
        </div>
      </div>
    </Card>
  );
};
